// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
/*
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

* {
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
  }
}*/

// Settings
@import "settings";

// Foundation
@import "../../../node_modules/foundation-sites/scss/foundation";

//@include foundation-global-styles;
//@include foundation-typography;
//@include foundation-forms;
//@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-flex-grid;
@import "vendor/owlcarousel";
@import "vendor/slick";
@import "vendor/magnific_popup";
@import "vendor/chosen";
@import "vendor/fontawesome-all";
//@import "vendor/buttons_hover";

@font-face {
  font-family: 'geometria_regular';
  src: url('../fonts/geometria-webfont.woff2') format('woff2'),
  url('../fonts/geometria-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'geometria_regular_italic';
  src: url('../fonts/geometria-italic-webfont.woff2') format('woff2'),
  url('../fonts/geometria-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'geometria_bold';
  src: url('../fonts/geometria-bold-webfont.woff2') format('woff2'),
  url('../fonts/geometria-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'geometria_light';
  src: url('../fonts/geometria-light-webfont.woff2') format('woff2'),
  url('../fonts/geometria-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'geometria_medium';
  src: url('../fonts/geometria-medium-webfont.woff2') format('woff2'),
  url('../fonts/geometria-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'geometria_heavy';
  src: url('../fonts/geometria-heavy-webfont.woff2') format('woff2'),
  url('../fonts/geometria-heavy-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'bebas_neuebold';
  src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
  url('../fonts/bebasneue_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/*vars*/
$black: #222;
$dark_gray: #626262;
$white: #fff;
$pink: #ea5798;
$body_bg: #fefcfa;
$orange: #ff9400;
$bronze: #a88a30;
$green: #1daa22;

@mixin body_text {
  color: $black;
  font-family: 'geometria_regular', sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
}

@mixin header_text {
  color: $black;
  font-family: 'geometria_regular', sans-serif;
}

@mixin subtitle_text {
  color: $dark_gray;
  font-family: 'geometria_medium', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 1px;
}

.column,
.columns {
  box-sizing: border-box;
}

/*ie fix*/
.filter__list .simplebar-track.horizontal {
  visibility: hidden!important;
}

input[type="submit"] {
  -webkit-appearance: none;
}

img {
  border: none;
}
/*ie fix ends*/

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background: $body_bg;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: $black;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

h3 {
  font-size: 36px;
  font-family: 'geometria_bold', sans-serif;
  color: $black;
  text-transform: uppercase;
  margin: 50px 0 0 0;
}

h4 {
  font-size: 28px;
  font-family: 'geometria_medium', sans-serif;
  color: $black;
  margin: 25px 0 25px;
}

h5 {
  font-size: 16px;
  font-family: 'geometria_bold', sans-serif;
  color: $pink;
  text-transform: uppercase;
  margin: 0;
}

p {
  @include body_text;
}

span {
  @include body_text;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

.section_title__underline {
  background: #ea5798;
  width: 640px;
  height: 1px;
  display: inline-block;
  margin-top: 20px;
  letter-spacing: 1px;
  margin-bottom: 25px;
}

.section_title {
  margin: 20px 0 0 0;
}

/*rating stars*/
.success-box {
  margin:50px 0;
  padding:10px 10px;
  border:1px solid #eee;
  background:#f9f9f9;
}

.success-box img {
  margin-right:10px;
  display:inline-block;
  vertical-align:top;
}

.success-box > div {
  vertical-align:top;
  display:inline-block;
  color:#888;
}

/* Rating Star Widgets Style */
.rating-stars ul {
  list-style-type:none;
  padding:0;

  -moz-user-select:none;
  -webkit-user-select:none;
}
.rating-stars ul > li.star {
  display:inline-block;
  width: 12px;
  cursor: default;
}

.rating-stars ul:not(#stars) {
  cursor: default;
}

#stars li.star {
  cursor: pointer;
}

/* Idle State of the stars */
.rating-stars ul > li.star > i.fa {
  font-size: 12px; /* Change the size of the stars */
  color:#ccc; /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > i.fa {
  color: lighten(#ffca00, 20%);
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > i.fa {
  color: #ffca00;
}
/*rating stars ends*/

/* Gamburger Menu */
.menu-icon {
  width: 26px;
  height: 22px;
  cursor: pointer;
  z-index: 50;
}
.menu-icon .line-1,
.menu-icon .line-2,
.menu-icon .line-3 {
  height: 2px;
  width: 100%;
  background-color: #000;
  border-radius: 3px;
  /*box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);*/
  transition: background-color 0.2s ease-in-out;
}
.menu-icon .line-1 {
  animation: animate-line-1-rev 0.7s ease-in-out;
}
.menu-icon .line-2 {
  margin: 8px 0;
  animation: animate-line-2-rev 0.7s ease-in-out;
}
.menu-icon .line-3 {
  animation: animate-line-3-rev 0.7s ease-in-out;
}
.menu-icon:hover .line-1,
.menu-icon:hover .line-2,
.menu-icon:hover .line-3 {
  background-color: #000;
}
.menu-icon.active .line-1,
.menu-icon.active .line-2,
.menu-icon.active .line-3 {
  background-color: #000;
}
.menu-icon.active .line-1 {
  animation: animate-line-1 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
}
.menu-icon.active .line-2 {
  animation: animate-line-2 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
}
.menu-icon.active .line-3 {
  animation: animate-line-3 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
}

.no-animation {
  -webkit-animation: none !important;
  animation: none !important;
}

@keyframes animate-line-1 {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(0, 10px, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, 10px, 0) rotate(45deg);
  }
}
@keyframes animate-line-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes animate-line-3 {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(0, -10px, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, -10px, 0) rotate(135deg);
  }
}
@keyframes animate-line-1-rev {
  0% {
    transform: translate3d(0, 10px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 10px, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}
@keyframes animate-line-2-rev {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes animate-line-3-rev {
  0% {
    transform: translate3d(0, -10px, 0) rotate(135deg);
  }
  50% {
    transform: translate3d(0, -10px, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

.header {
  background: $white;
  border-bottom: 1px solid #ccc;
}

.header__profile_trigger {
  > header__icon_link active {
    position: relative;
    z-index: 200;
    pointer-events: all;
  }
}

.header__top {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  &::after {
    content: '';
    background: #ea5798;
    width: 100%;
    height: 1px;
    max-width: 1170px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
  }
}

.header__gamburger_menu {
  margin-right: 45px;
  position: relative;
}

.header__gamburger_menu_list {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 20px;
  margin: 0;
  position: absolute;
  z-index: 100;
  background: #fff;
  left: 15px;
  top: 30px;
  width: 300px;
  box-sizing: border-box;
  display: none;
}

.header__search_input {
  position: absolute;
  right: 0;
  top: -4px;
  border: none;
  border-bottom: 2px solid transparent;
  background: transparent;
  z-index: -1;
  height: 32px;
  width: 0px;
  @include body_text;
  box-sizing: border-box;
  padding-right: 30px;
  padding-top: 1px;
  display: block;
  opacity: 0;
  transition: ease-out .4s;
  &:focus {
    outline: none;
    border-bottom: 1px solid $pink;
  }
  &:valid {
    border-bottom: 1px solid $pink;
  }
}

.header__search {
  position: relative;
  .header__icon_link.active ~ .header__search_input {
    display: block;
    width: 200px;
    opacity: 1;
    transition: ease-out .35s;
  }
  .header__icon_link.active ~ .search__auto_complete {
    display: block;
  }
}

.search__show_more {
  height: 35px;
  max-width: 200px;
  margin: 0 auto 15px;
}

.search__auto_complete {
  display: none;
  position: absolute;
  top: 28px;
  right: -50px;
  width: 300px;
  background: $white;
  margin: 0;
  padding: 15px 0;
  @media (max-width: 639px) {
    right: 0;
    width: 280px;
    top: 28px;
  }
  .not_found {
    padding: 0 30px;
  }
  .product__price {
    font-family: 'geometria_bold', sans-serif;
    font-size: 14px;
    text-align: center;
    > a {
      color: $pink;
    }
  }
  .product__text {
    font-family: 'geometria_medium', sans-serif;
    font-size: 12px;
    margin: 0;
  }
  .product__img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .auto_complete__product {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.header__gamburger_menu_link {
  @include body_text;
  font-family: 'geometria_medium', sans-serif;
  display: block;
  margin-bottom: 10px;
  &:hover {
    color: $pink;
  }
}

.header__phone_wrapper {
  padding: 0;
}
.phone_wrapper__item {
  list-style: none;
  display: block;
  .phone_wrapper__item {
    @include header_text;
  }
  &.phone_num {
    @include header_text;
    font-size: 18px;
  }
  &.working_hours {
    @include header_text;
    font-size: 9px;
  }
}

.header__logo {
  text-align: center;
  .logo__link {
    display: inline-block;
    height: 66px;
    overflow: hidden;
  }
  .logo__img {
    display: inline-block;
  }
}

.logo__text {
  display: block;
  font-size: 14px;
  font-family: 'geometria_regular', sans-serif;
  color: $dark_gray;
  position: relative;
  top: -7px;
}

.header__icon {
  padding: 0;
  position: relative;
  z-index: 10;
  &:not(:last-child) {
    margin-right: 40px;
  }
  &:last-child {
    margin-right: 15px;
  }
  &.search {
    .header__icon_link {
      width: 22px;
      height: 19px;
      display: block;
      background: url('../img/header_search.svg') no-repeat;
      /*&:hover {
        background: url('../img/header_search_hover.svg') no-repeat;
      }*/
    }
  }
  &.sale {
    .header__icon_link {
      width: 40px;
      height: 26px;
      display: block;
      background: url('../img/header_sale.svg') no-repeat;
      /*&:hover {
        background: url('../img/header_sale_hover.svg') no-repeat;
      }*/
    }
  }
  &.profile {
    .header__icon_link {
      width: 21px;
      height: 28px;
      display: block;
      background: url('../img/header_profile.svg') no-repeat;
      &.active {
        background: url('../img/header_profile_hover.svg') no-repeat;
      }
    }
  }
  &.cart {
    .header__icon_link {
      width: 34px;
      height: 32px;
      display: block;
      background: url('../img/header_cart.svg') no-repeat;
      &.active {
        background: url('../img/header_cart_hover.svg') no-repeat;
      }
    }
  }
}

.header__profile_menu {
  display: none;
  width: 254px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  top: 35px;
  right: -15px;
  /*padding: 30px;*/
  box-sizing: border-box;
  z-index: 101;
  &::before {
    content: '';
    background: transparent;
    width: 100%;
    height: 10px;
    display: block;
    top: -10px;
    left: 0;
    position: absolute;
  }
  .profile_menu__button {
    @extend .add_to_cart__button;
    width: 193px;
    height: 48px;
    border: none;
    font-size: 18px;
    padding-top: 1px;
    cursor: pointer;
    /*background: $pink;*/
  }
}

.profile_menu__subscribe {
  float: left;
  width: 84%;
  font-size: 12px;
  font-family: 'geometria_regular', sans-serif;
  color: $pink;
  position: relative;
  text-decoration: underline;
  text-align: left;
  margin-bottom: 30px;
}

.profile_menu__tabs_content {
  display: none;
  &.active {
    display: block;
  }
}

.fill_in_the_form {
  text-align: center;
}

.fill_in_the_form__text {
  @include body_text;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  strong {
    font-family: 'geometria_bold', sans-serif;
    font-weight: 700;
  }
}

.fill_in_the_form__link {
  font-family: 'geometria_regular', sans-serif;
  font-size: 16px;
  color: $pink;
  display: inline-block;
  text-align: center;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: $pink;
  }
}

.profile_menu__tabs_heading {
  text-align: center;
  margin: 0;
}

.profile_menu__title {
  @include body_text;
  font-family: 'geometria_medium', sans-serif;
  margin-bottom: 30px;
  padding: 20px 0;
  display: inline-block;
  float: left;
  text-align: center;
  width: 50%;
  box-sizing: border-box;
  cursor: pointer;
  &:nth-child(1):not(.active) {
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    color: $pink;
  }
  &:nth-child(2):not(.active) {
    border-left: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    color: $pink;
  }
}

.profile_menu__tabs_content_wrapper {
  padding: 30px 30px 10px 30px;
}

.profile_menu__input {
  @include body_text;
  font-size: 13px;
  font-family: 'geometria_regular_italic', sans-serif;
  font-style: italic;
  border: 1px solid #e4e4e4;
  width: 193px;
  height: 36px;
  margin-bottom: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.profile_menu__recover_password,
.profile_menu__register {
  @extend .article_preview__read_more;
}


.profile_menu__recover_password_wrapper {
  text-align: right;
  .profile_menu__recover_password {
    margin-right: 0;
  }
}

.profile_menu__register_wrapper {
  text-align: center;
  .profile_menu__register {
    margin-bottom: 0;
    margin-top: 20px;
    margin-right: 0;
  }
}

.header__cart_menu {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  width: 288px;
  position: absolute;
  background: $white;
  top: 37px;
  right: 0;
  z-index: 120;
  display: none;
}

.header__cart_empty {
  @include body_text;
  text-align: center;
  display: block;
  margin-bottom: 30px;
  color: $pink;
}

.cart_menu__title {
  @include body_text;
  font-family: 'geometria_bold', sans-serif;
  font-weight: 700;
  color: $black;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
}

.cart_menu__product {
  position: relative;
}

.cart_menu__product_img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.cart_menu__product_text_wrapper {
  text-align: center;
  .cart_menu__product_link {
    position: relative;
    top: 7px;
  }
}

.cart_menu__product_text {
  font-family: 'geometria_medium', sans-serif;
  font-size: 12px;
  margin: 0;
  min-height: 37px;
}

.cart_menu__product_price {
  font-family: 'geometria_bold', sans-serif;
  font-size: 14px;
  color: $pink;
  margin-top: 5px;
}

.cart_menu__total_wrapper {
  text-align: right;
  position: relative;
  padding-top: 10px;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 192px;
    height: 1px;
    background: #ccc;
  }
  .cart_menu__checkout {
    width: 181px;
    height: 38px;
    padding-top: 10px;
    margin: 0 auto 30px;
  }
}

.cart_menu__total_text {
  font-family: 'geometria_medium', sans-serif;
  margin: 0;
  color: $black;
  font-size: 18px;
  margin-right: 40px;
  margin-bottom: 25px;
  display: block;
}

.cart_menu__total_value {
  color: $pink;
  font-size: 18px;
  font-weight: 700;
  font-family: 'geometria_bold', sans-serif;
}

.cart_menu__product_link {
  display: block;
}

.cart_menu__product_img_wrapper {
  .cart_menu__product_link {
    width: 70px;
    height: 70px;
    padding: 5px;
    box-sizing: border-box;
  }
}

.cart_menu__remove_product {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  margin: auto;
  width: 13px;
  height: 13px;
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    display: block;
    background: #ff0303;
    width: 13px;
    height: 1px;
    position: absolute;
    top: 6px;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.main_menu {
  text-align: center;
  position: relative;
  .sub_menu {
    display: none;
    position: absolute;
    left: 15px;
    top: 32px;
    width: 1100px;
    background: $white;
    z-index: 100;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    padding: 25px 35px 10px;
    &::before {
      content: '';
      width: 100%;
      height: 17px;
      background: transparent;
      position: absolute;
      top: -17px;
      left: 0;
    }
  }
  .sub_menu__item {
    text-align: left;
    margin-bottom: 12px;
    display: block;
    &.static {
      .sub_menu__link {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        color: $black;
        &::after {
          content: '';
          background: $pink;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: -3px;
          left: 0;
        }
      }
    }
  }
  .sub_menu__link {
    margin-bottom: 15px;
    font-family: 'geometria_medium', sans-serif;
    font-weight: 500;
    display: block;
    letter-spacing: 1px;
    font-size: 16px;
    color: $pink;
    text-transform: uppercase;
    &:hover {
      color: $pink;
    }
  }
  .sub_menu__column {
    width: 25%;
    float: left;
    padding-right: 20px;
    box-sizing: border-box;
    &.sub_menu__img_wrapper {
      width: 50%;
    }
  }
  .sub_menu__img {
    float: right;
    margin-top: 30px;
  }
  .sub_menu__sub_category_link {
    @include body_text;
    color: $dark-gray;
    text-transform: none;
    display: block;
    margin-bottom: 10px;
    margin-left: 4px;
    //text-transform: capitalize;
    &:hover {
      color: $pink;
    }
  }
}

.main_menu__item {
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 20px;
  .main_menu__link {
    @include header_text;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:not(:last-child) {
    > .main_menu__link {
      position: relative;
      &::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        right: -26px;
        top: 6px;
        display: block;
        background: $pink;
      }
    }
  }
  &.active {
    .main_menu__link {
      color: $pink;
      font-family: 'geometria_bold', sans-serif;
    }
  }
  .main_menu__link {
    &:hover {
      color: lighten($pink, 15%)
    }
  }
}

.header__bottom {
  padding-bottom: 15px;
}

.header__profile_menu {
  .error_box {
    > span {
      color: red;
    }
  }
}

  /*header ends*/

/*homepage*/
/*main slider*/
.main_slider {
  margin-bottom: 35px;
  /*border-top: 1px solid #ccc;*/
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}

.slide__text_wrapper {
  display: inline-block;
  position: absolute;
  top: 140px;
}

.slide__text {
  &.text_top {
    color: $black;
    font-family: 'geometria_regular', sans-serif;
    font-size: 30px;
    text-align: right;
    display: block;
    margin-right: 10px;
  }
  &.text_middle {
    color: $black;
    font-family: 'bebas_neuebold', sans-serif;
    font-size: 110px;
    text-transform: uppercase;
    margin: 12px 0 0;
    line-height: 78px;
    letter-spacing: 9px;
  }
  &.text_bottom {
    color: $bronze;
    font-family: 'bebas_neuebold', sans-serif;
    font-size: 48px;
    text-transform: uppercase;
    line-height: 35px;
    margin-top: 12px;
  }
}

.owl-carousel {
  position: relative;

}
.owl-nav {
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: 0;
  bottom: 0;
  @media (min-width: 1440px) {
    bottom: 50px;
  }
  left: 0;
  margin: auto;
  height: 35px;
}

.manufacturers,
.main_slider__wrapper  {
  .owl-nav {
    bottom: 0;
  }
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  width: 35px;
  height: 35px;
  background: url('../img/slider_arrow.svg');
  pointer-events: all;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  &.disabled {
    display: none;
  }
}

.owl-carousel .owl-nav .owl-prev {
  float: left;
  margin-left: 55px;
  transform: rotate(180deg);
  &:hover {
    background: url('../img/slider_arrow_active.svg');
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.owl-carousel .owl-nav .owl-next {
  float: right;
  margin-right: 55px;
  &:hover {
    background: url('../img/slider_arrow_active.svg');
    transform: rotate(0deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.owl-carousel .owl-dots {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: transparent;
  border: 2px solid $pink;
  pointer-events: all;
  margin: 0 7px;
  &.active {
    background: $pink;
  }
}
/*main slider ends*/

/*product card*/

.badges__wrapper {
  position: absolute;
  top: 0;
  display: inline-block;
  z-index: 2;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  .badge {
    display: block;
    box-sizing: border-box;
    &:not(.sale){margin: 10px 0 10px 10px};
    &.discount {
      width: 40px;
      height: 40px;
      background-color: #f66449;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      text-align: center;
      padding-top: 12px;
      color: $white;
      font-size: 12px;
      font-family: 'geometria_regular', sans-serif;
    }
    &.mysale {
      width: 40px;
      height: 26px;
      background: url('../img/header_sale.png');
    }
    &.sale {
      width: 71px;
      height: 60px;
      background: url('../img/sale_badge.png');
      position: absolute;
      right: -6px;
      top: -7px;
    }
  }
}

.product__row {
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    height: 370px;
    margin-bottom: 100px;
  }
}

.product_card__outer_wrapper {
  padding: 0 15px;
  &.sale {
    .product_card__img_wrapper {
      height: 313px;
      position: relative;
    }
    .product_card__img {
      display: block;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
    }
    .product_card__title {
      text-align: center;
      min-height: 40px;
    }
  }
}

.sale_cat {

  @media (max-width: 799px) {
    .product_card__outer_wrapper {
      &.sale {
        .product_card__sale_inner {
          max-width: 370px;
          margin: 0 auto;
        }
      }
    }
  }

  .product_card__outer_wrapper.sale {
    .product_card__img_wrapper {
      height: auto;
      max-height: 270px;
      margin-bottom: 20px;
    }
  }

  .product__row {
    height: auto;
    margin-bottom: 30px;
  }

  .sale_ends_text {
    color: $pink;
    text-align: center;
    margin-bottom: 24px;
  }

  .product_card__img_wrapper {
    .product_card__link {
      max-height: 244px;
      height: auto;
      overflow: hidden;
      display: block;
      > img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }
    }
  }

}

.category__view:not(.sale_cat):not(.search) {
  .product_card__outer_wrapper {
    @media (min-width: 1200px) {
      max-width: 25%;
    }
  }
  &.search {
    .product_card__outer_wrapper {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  &.single_brand {
    .product_card__outer_wrapper {
      @media (min-width: 1200px) {
        max-width: 20%;
      }
    }
  }
}

.has_no_filters {
  .product_card__outer_wrapper {
    @media (min-width: 1200px) {
      max-width: 20%;
    }
  }
}

.product_card__inner_wrapper {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: $white;
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
  @media (min-width: 1200px) {
    max-width: 210px;
  }
  .product_characteristics__list {
    display: none;
  }
}

.product_card_modal__sku {
  display: block;
}

.product_card_modal__img {
  max-width: 100%;
}

.product_card__img_wrapper {
  height: 226px;
  position: relative;
  /*overflow: hidden;*/
}

.product_card__img {
  margin: 0 auto;
  display: block;
  height: auto;
  width: 100%;
}

.product_card__price {
  width: 114px;
  height: 48px;
  display: block;
  background: url('../img/brush.png');
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 10px;
  box-sizing: border-box;
  text-align: left;
  &.no_discount {
    padding-top: 15px;
  }
  .price_crossed {
    color: $white;
    font-family: 'geometria_regular', sans-serif;
    display: block;
    font-size: 11px;
    text-decoration: line-through;
  }
  .price {
    color: $white;
    font-family: 'geometria_regular', sans-serif;
    display: block;
    font-size: 14px;
  }
}

.product_card__rating {
  display: inline-block;
  margin-right: 15px;
  .rating-stars ul {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.product_card__testimonials {
  display: inline-block;
  .testimonials__counter {
    font-size: 14px;
    font-family: 'geometria_regular', sans-serif;
    color: #32a3b7;
    padding-right: 19px;
    background: url('../img/comments_icon.svg') no-repeat right center;
    background-size: 14px 12px;
  }
}

.product_card__title {
  @include subtitle_text;
  padding: 0 15px 0;
  min-height: 61px;
  max-height: 61px;
  overflow: hidden;
  margin-bottom: 10px;
}

.product_card__brand {
  margin-bottom: 5px;
  margin-top: 0;
}

.product_card__brand_link {
  @include subtitle_text;
  color: $pink;
  text-transform: none;
}

.button {
  display: block;
  font-size: 14px;
  font-family: 'geometria_medium', sans-serif;
  color: $white;
  text-align: center;
  background: $pink;
  padding-top: 8px;
  box-sizing: border-box;

  background-color: $pink;
  border: 1px solid transparent;
  color: $white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: transparent;
    color: $pink;
    border: 1px solid $pink;
  }
}

.product_card__add_to_cart {
  padding-bottom: 1px;
  display: none;
  padding: 0 15px;
  .add_to_cart__button {
    width: 125px;
    height: 35px;
    margin: 0 auto 20px;

    background-color: $pink;
    border: 1px solid transparent;
    color: $white;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: transparent;
      color: $pink;
      border-color: $pink;
    }
  }
}

.product__option_label {
  &.color_opt {
    width: 20px;
    height: 20px;
    padding: 0;
    overflow: hidden;
  }
}

.product_card__select {
  height: 31px;
  border: 1px solid #ccc;
  padding-top: 3px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  padding-right: 22px;
  padding-left: 22px;
  overflow: hidden;
  line-height: 22px;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 3px 0 3px;
    border-color: $pink transparent transparent transparent;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.modal_product_size_1 {

}

/*prod opts modal*/
.modal_content__right .product_card__title {
  padding: 0;
  min-height: 0;
  font-size: 18px;
  color: $dark_gray;
  @media (max-width: 639px) {
    text-align: center;
  }
}

@media (max-width: 639px) {
  .modal_content__right .product_card__brand {
    text-align: center;
  }
}

/*.modal_content__right .product_card__brand_link {

}*/

.modal_content__right .product_card_modal__sku {
  margin-top: 5px;
}

.modal_content__right .product_card_modal__price {
  font-size: 24px;
  font-family: 'geometria_bold', sans-serif;
  font-weight: 700;
  color: $pink;
  margin-top: 15px;
  display: block;
}

.reveal.product_modal {
  max-width: 730px;
}

.product_characteristics__list {
  height: 190px;
  border: 1px solid #ccc;
}

.modal_content__right .add_to_cart__button {
  max-width: 125px;
  height: 36px;
  @media (max-width: 639px) {
    margin: 0 auto;
  }
}

@media (max-width: 479px) {
  .reveal.product_modal {
    padding: 30px;
  }
  .modal_content__right {
    margin-bottom: 0;
  }
}

.product_characteristics__item {
  &.active {
    background: #eee;
  }
  &.other {
    label {
      padding-left: 2px;
      &.not_available {
        text-decoration: line-through;
      }
    }
  }
}

.product_characteristics__item {
  padding: 6px;
  height: 30px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  input[type="radio"] {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }
  label {
    font-size: 14px;
    font-family: 'geometria_regular', sans-serif;
    color: $dark_gray;
    float: left;
    margin-left: 10px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-left: 30px;
    padding-top: 7px;
    height: 30px;
    box-sizing: border-box;
    z-index: 10;
  }
}
.product_card_modal__opt_thumb {
  float: left;
  pointer-events: none;
}
.product_card_modal__opt_thumb_wrapper {
  display: inline-block;
  margin-left: 5px;
  overflow: hidden;
  border: 1px solid #cccccc;
  &.not_available {
    position: relative;
    .product_card_modal__opt_thumb {
      opacity: .5;
    }
    &::before {
      content: '';
      display: block;
      width: 28px;
      height: 2px;
      background: #ddd;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: -4px;
      top: 9px;
      z-index: 2;
    }
  }
}
.modal_content__left {
  position: relative;
}
.modal_content__right {
  padding-top: 40px;
  margin-bottom: 40px;
}
.product_card_modal__img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  max-width: 100%;
  @media (max-width: 639px) {
    position: static;
  }
}

/*product card ends*/

/*product slider*/
.sale_products__slider,
.bestseller_products,
.new_products__slider,
.product__view_sale_slider {
  .owl-stage-outer {
    padding: 7px 0 95px;
    height: 370px;
  }
  .owl-nav {
    .owl-next {
      right: -80px;
      position: relative;
      margin: 0;
    }
    .owl-prev {
      left: -80px;
      position: relative;
      margin: 0;
    }
  }
}
/*product slider ends*/
.sale_block {
  margin-top: 30px;
  margin-bottom: 50px;
}

.sale_block__img_wrapper {
  position: absolute;
  left: 0;
  top: 0;

  /*&::before {
    content: '';
    background: rgba(255,255,255,.85);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }*/
}

.sale_block__img {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.sale_block__left_col {
  position: relative;
  padding-right: 0;
  /*&::before {
    content: '';
    width: 379px;
    height: 355px;
    background: url('../img/flowers.png');
    position: absolute;
    left: 0;
    bottom: 0;
  }*/
  .inner_wrapper {
    position: relative;
    height: 498px;
    //background: url('../img/sale_left.png') no-repeat;
    background-size: cover;
    //box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    padding: 66px;
    box-sizing: border-box;
    /*&::before {
      content: '';
      border: 10px solid $white;
      width: 85%;
      height: 85%;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &::after {
      content: '';
      width: 379px;
      height: 355px;
      display: block;
      background: url('../img/flowers.png') no-repeat;
      position: absolute;
      bottom: -18px;
      left: -22px;
      margin: auto;
    }*/
  }
}

.sale_block__right_col {
  position: relative;
  padding-left: 0;
  /*&::before {
    content: '';
    width: 322px;
    height: 319px;
    background: url('../img/flowers_2.png');
    position: absolute;
    right: 40px;
    bottom: 15px;
  }*/
  .inner_wrapper {
    position: relative;
    height: 498px;
    //background: url('../img/sale_right.png') no-repeat;
    background-size: cover;
    //box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    padding: 66px;
    box-sizing: border-box;
    /*&::before {
      content: '';
      border: 10px solid $white;
      width: 85%;
      height: 85%;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &::after {
      content: '';
      width: 322px;
      height: 319px;
      display: block;
      background: url('../img/flowers_2.png') no-repeat;
      position: absolute;
      bottom: 17px;
      right: 18px;
      margin: auto;
    }*/
  }
}

.sale_block__title {
  position: relative;
  z-index: 3;
  font-size: 26px;
  font-family: 'geometria_heavy', sans-serif;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 60px;
  .title_orange {
    color: $orange;
  }
  .title_pink {
    color: $pink;
  }
}

.sale_block__text {
  position: relative;
  z-index: 3;
  font-size: 16px;
  font-family: 'geometria_light', sans-serif;
  color: $black;
  max-width: 260px;
  letter-spacing: 1px;
  margin-bottom: 66px;
}

.sale_block__right_col {
  .sale_block__text {
    left: 50px;
  }
}

.sale_block__left_col {
  .sale_block__text {
    left: 160px;
  }
}

.sale_block__btn {
  @extend .button;
  position: relative;
  z-index: 3;
  width: 160px;
  height: 46px;
  border: 1px solid $pink;
  background: transparent;
  padding-top: 13px;
  .btn__text {
    font-size: 14px;
    font-family: 'geometria_medium', sans-serif;
    color: $black;
    position: relative;
    padding-right: 26px;
    letter-spacing: 1px;
  }
  .btn__arrow {
    width: 15px;
    height: 1px;
    background: $black;
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 1px;
      background: $black;
      position: absolute;
      right: 0;
    }
    &::before {
      transform: rotate(-45deg);
      top: 3px;
    }
    &::after {
      transform: rotate(45deg);
      top: -3px;
    }
  }
}

.sale_block__price {
  position: relative;
  z-index: 3;
  letter-spacing: 1px;
  font-size: 24px;
  font-family: 'geometria_bold', sans-serif;
}

.sale_block__right_col {
  .sale_block__price  {
    color: $pink;
    margin-top: 80px;
  }
}

.sale_block__left_col {
  .sale_block__price {
    color: $orange;
    text-align: right;
    margin-top: 80px;
  }
}

.sale_block__right_col {
  .sale_block__btn {
    margin-left: 50px;
  }
}

.sale_block__left_col {
  .sale_block__btn {
    margin-left: 160px;
  }
}

/*blog*/
.blog {
  background: #f9f9f9;
  padding-top: 35px;
  padding-bottom: 75px;
}

.article_preview__inner_wrapper {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  &:hover {
    .article_preview__desc {
      height: 90px;
      transition: .3s ease-out;
    }
    .article_preview__img_wrapper {
      height: 154px;
      transition: .3s ease-out;
    }
    .article_preview__img {
      transition: .3s ease-out;
      filter: blur(5px);
    }
    .article_preview__icon {
      transition: .3s ease-out;
      opacity: 1;
    }
  }
}

.article_preview__icon {
  background: url('../img/news_details.png');
  height: 60px;
  width: 60px;
  position: absolute;
  top: 31%;
  left: 42%;
  transition: .3s ease-out;
  opacity: 0;
  pointer-events: none;
}

.article_preview__desc {
  overflow: hidden;
  padding: 0 20px;
  height: 0;
  transition: .3s ease-out;
}

.article_preview__desc_text {
  color: $dark-gray;
}

.article_preview__img_wrapper {
  overflow: hidden;
  height: 244px;
  transition: .3s ease-out;
  position: relative;
}

.article_preview__img {
  width: auto;
  height: auto;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  margin: auto;
  transition: .3s ease-out;
  filter: blur(0px);
}

.article_preview__title {
  @include subtitle_text;
  text-align: center;
  padding: 0 45px;
  margin-bottom: 10px;
  min-height: 60px;
}

.article_preview__date {
  color: $pink;
  margin-left: 15px;
  margin-bottom: 8px;
}

.article_preview__read_more_wrapper {
  text-align: right;
}

.article_preview__read_more {
  color: $pink;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 20px;
  font-family: 'geometria_regular', sans-serif;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $pink;
  }
}
/*blog ends*/

/*manufacturers*/
.manufacturers__slider {
  .owl-nav {
    .owl-prev {
      left: -80px;
      position: relative;
      margin: 0;
    }
    .owl-next {
      right: -80px;
      position: relative;
      margin: 0;
    }
  }
}
/*manufacturers ends*/

/*about shop*/
.about_shop {
  margin-bottom: 50px;
  a {
    color: $pink;
  }
  ul {
    li {
      color: $black;
      font-family: 'geometria_regular', sans-serif;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1px;
      margin-bottom: 5px;
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        background: black;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
  .about_shop__title_wrapper {
    text-align: center;
    .section_title {
      font-size: 36px;
      font-family: 'geometria_bold', sans-serif;
      color: $black;
      text-transform: uppercase;
      margin: 25px 0 0 0;
    }
    .section_title__underline {
      margin-bottom: 0;
      width: 776px;
    }
  }
}

.about_shop__img_wrapper {
  margin-top: 30px;
  display: inline-block;
}

.about_shop__img {
  width: 100%;
}

.about_shop__read_more_wrapper {
  text-align: right;
}

.about_shop__read_more {
  @extend .article_preview__read_more;
}

.about_shop__subtitle {
  font-size: 22px;
  font-family: 'geometria_bold', sans-serif;
  color: #222;
  text-transform: uppercase;
  margin: 25px 0 0 0;
}
/*about shop ends*/

/*register page*/
.register_page__btn {
  width: 193px;
  height: 48px;
  font-size: 18px;
  font-family: 'geometria_medium', sans-serif;
  color: #fff;
  padding-top: 0;
  margin-top: 10px;
  cursor: pointer;
}

.register_page__bonuses_text_pink {
  font-size: 24px;
  font-family: 'geometria_bold', sans-serif;
  color: $pink;
}

.register_page__bonuses_text_bold {
  font-family: 'geometria_bold', sans-serif;
  font-weight: 700;
}

.register_page__form {
  .error-box {
    &.isVisible {
      margin-bottom: 15px;
    }
  }
}
/*register page ends*/

/*footer*/
.footer {
  background: #f9f9f9;
  padding: 50px 0 0;
}

.footer__logo {
  text-align: center;
  .logo__img {
    display: inline-block;
  }
}

.footer__social {
  text-align: center;
}

.footer__social_item {
  display: inline-block;
  margin: 0 7px;
}

.footer__social_link {
  width: 18px;
  height: 18px;
  display: block;
  &.facebook {
    background: url('../img/facebook.png') no-repeat center center;
    &:hover {
      background: url('../img/facebook_hover.png') no-repeat center center;
    }
  }
  &.instagram {
    background: url('../img/instagram.png') no-repeat center center;
    &:hover {
      background: url('../img/instagram_hover.png') no-repeat center center;
    }
  }
  &.telegram {
    background: url('../img/telegram.png') no-repeat center center;
    &:hover {
      background: url('../img/telegram_hover.png') no-repeat center center;
    }
  }
}

.footer__title {
  margin-bottom: 15px;
}

.footer__menu {
  margin: 0;
}

.footer__menu_link {
  @include body_text;
  margin-bottom: 5px;
  display: block;
  &:hover {
    color: #32a3b7;
    span {
      color: #32a3b7;
    }
  }
}

.footer__copyright {
  text-align: center;
  margin-top: 15px;
}

.footer__copyright_link {
  color: $pink;
  &:hover {
    color: #32a3b7;
  }
}

.footer__menu_item {
  @include body_text;
  margin-bottom: 5px;
  display: block;
}
.footer__contacts {
  .footer__menu_item {
    .footer__menu_text {
      position: relative;
      display: block;
      padding-left: 25px;
      //padding-bottom: 5px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
      }
    }
    &.adress {
      .footer__menu_text {
        &::before {
          background: url('../img/footer_location.png') no-repeat;
          width: 13px;
          height: 19px;
          top: 0;
        }
      }
    }
    &.adress_no_icon {
      padding-left: 25px;
    }
    &.phone {
      .footer__menu_text {
        &::before {
          background: url('../img/footer_phone.png') no-repeat;
          width: 16px;
          height: 14px;
          top: 0;
        }
      }
    }
    &.email {
      .footer__menu_text {
        &::before {
          background: url('../img/footer_mail.png') no-repeat;
          width: 16px;
          height: 14px;
          top: 3px;
        }
      }
    }
  }
}
/*footer end*/
/*homepage ends*/

/*breadcrumbs*/
.breadcrumbs__item {
  display: inline-block;
}

.breadcrumbs__link {
  @include body_text;
  color: $pink;
}

.breadcrumbs__item {
  &:not(.active)::after {
    content: '>';
    margin: 0 5px;
    color: $black;
  }
  &.active {
    @include body_text;
    color: $black;
  }
}
/*breadcrumbs ends*/

/*404 error page*/
.error_page__img {
  margin: 100px auto 40px;
  display: block;
  max-width: 100%;
}

.error_page__text {
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  margin-bottom: 120px;
}

.error_page__link {
  color: $pink;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

/*404 error page ends*/

/*static page*/
.static_page {
  .static_page__title {
    font-size: 36px;
    margin: 0;
    @media (max-width: 639px) {
      font-size: 20px;
    }
    font-family: 'geometria_bold', sans-serif;
    color: $black;
    text-transform: uppercase;
  }
  ol {
    li {
      list-style: decimal;
    }
  }
  ul {
    li {
      list-style: disc;
    }
  }
}
/*static page ends*/

/*category page*/
.category__title {
  @extend h3;
  margin: 0;
  font-family: 'geometria_medium', sans-serif;
  margin-bottom: 35px;
  border-bottom: 1px solid $pink;
  padding-bottom: 20px;
  font-size: 26px;
}

.category__my_sale {
  width: 40px;
  height: 26px;
  display: inline-block;
  background: url('../img/header_sale.svg') no-repeat;
  float: left;
  margin-top: -5px;
}

.category__sort_by {
  position: relative;
  top: 49px;
  margin-top: 5px;
  display: inline-block;
  float: right;
  &::after {
    content: '';
    position: absolute;
    display: block;
    border: 5px solid transparent;
    border-top: 5px solid $pink;
    right: 0;
    top: 8px;
  }
}

.sort_by__text {
  position: relative;
}

.pseudo_select {
  display: inline-block;
  max-width: 145px;
}

.pseudo_select__list {
  display: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 160px;
  position: absolute;
  z-index: 100;
  top: 28px;
  right: -1px;
  margin: 0;
  box-sizing: border-box;
  padding: 5px 0 0;
}

.pseudo_select__item {
  margin-bottom: 7px;
}

.pseudo_select__link {
  padding: 5px 25px;
  display: block;
  @include body_text;
  &:hover:not(.active) {
    background: $pink;
    color: $white;
  }
  &.price_asc {
    background: url('../img/price_asc.png') no-repeat 7px center;
    &:hover:not(.active) {
      background: url('../img/price_asc_hover.png') no-repeat 7px center $pink;
      color: $white;
    }
  }
  &.price_desc {
    background: url('../img/price_desc.png') no-repeat 7px center;
    &:hover:not(.active) {
      background: url('../img/price_desc_hover.png') no-repeat 7px center $pink;
      color: $white;
    }
  }
  &.active {
    position: relative;
    padding: 0 18px;
    &.price_asc {
      background: url('../img/price_asc_active.png') no-repeat 1px center;
    }
    &.price_desc {
      background: url('../img/price_desc_active.png') no-repeat 1px center;
    }
    .pseudo_select__text {
      @include body_text;
      color: $pink;
      position: relative;
      display: block;
      &::before {
        content: '';
        background: $pink;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -2px;
      }
    }
  }
}

.filter__list {
  margin-bottom: 20px;
  max-height: 230px;
  overflow-y: hidden;
  display: none;
}

.category__filter {
  background: $white;
  padding: 30px 15px 1px;
}

.filter__item {
  margin-bottom: 2px;
}

.filter__color_img {
  display: inline-block;
  float: left;
  margin-left: 10px;
}

.filter__input_text {
  float: left;
  display: inline-block;
}

.filter__heading {
  @include body_text;
  font-family: 'geometria_medium', sans-serif;
  text-transform: uppercase;
  margin-bottom: 30px;
  display: block;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    display: block;
    border: 5px solid transparent;
    border-top: 5px solid $pink;
    right: -3px;
    top: 5px;
    transform: rotate(0deg);
    transition: .3s ease-out;
  }
  &.active {
    &::after {
      content: '';
      transform: rotate(180deg);
      top: 2px;
      transition: .3s ease-out;
    }
  }
}

.category__description,
.single_post {
  position: relative;
  padding-top: 20px;
  &::before {
    content: '';
    width: 100%;
    max-width: 640px;
    height: 1px;
    background: $pink;
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  * {
    color: $black;
    font-family: 'geometria_regular', sans-serif!important;
  }
  a {
    color: $pink;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
    font-family: 'geometria_bold', sans-serif!important;
    line-height: 100%;
    margin: 25px 0;
    text-transform: none;
    * {
      line-height: 100%;
    }
    a {
      color: $pink;
    }
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 18px;
  }

  @media (max-width: 799px) {
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 16px;
    }
  }
}

.checked_filters__wrapper {
  margin: 0 0 30px;
}

.checked_filters {
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  &::after,
  &::before {
    content: '';
    width: 10px;
    height: 1px;
    display: block;
    position: absolute;
    left: 0;
    top: 8px;
    background: $pink;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}

.checked_filters__title {
  @extend .filter__heading;
  &::before,
  &::after {
     display: none;
  }
}

.drop_filter {
  @extend .profile_menu__recover_password;
  display: inline-block;
  cursor: pointer;
}

.product__tabs_content {
  display: none;
  &.active {
    display: block;
  }
}
/*category page ends*/

/*product page*/
.product__price_row {
  margin-bottom: 30px;
}

.product__top_row {
  margin-bottom: 45px;
}

.product__rating_row {
  margin-bottom: 15px;
}

.product__main_info_row {
  margin-bottom: 10px;
}

.product__options_row {
  margin-bottom: 20px;
}

.product__add_to_cart_row {
  margin-bottom: 30px;
}

.product__left_column {
  padding-right: 0;
}

.product__right_column {
  padding-left: 0;
}

.product__additional_images {
  overflow: hidden;
}

.product__left_column_inner_wrapper {
  background: $white;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
  padding: 55px 30px;
  box-sizing: border-box;
  //overflow: hidden;
  height: 566px;

  .badges__wrapper {
    &.left {
      left: 6px;
      top: -45px;
    }
    &.right {

    }
    .badge {
      &:not(.sale){

      };
      &.discount {
        padding-top: 17px;
        width: 51px;
        height: 51px;
      }
      &.mysale {
        background: url('../img/badge_mysale_big.png') no-repeat;
        width: 55px;
        height: 36px;
      }
      &.sale {
        background: url('../img/badge_sale_big.png') no-repeat;
        width: 93px;
        height: 83px;
        top: -64px;
        right: -53px;
      }
    }
  }

}

.product__right_column_inner_wrapper {
  background: #f9f9f9;
  padding: 30px;
  min-height: 566px;
  box-sizing: border-box;
}

.product__title {
  font-size: 18px;
  font-family: 'geometria_heavy', sans-serif;
  color: $black;
  text-transform: uppercase;
  margin: 0 0 25px;
  &.sale {
    font-size: 36px;
    font-family: 'geometria_bold', sans-serif;
    color: #222;
    text-transform: uppercase;
    //margin: 50px 0 0 0;
    margin: 0;
  }
}

.product_card__sale_inner {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.product__manufacturer {
  .manufacturer_text {
    text-transform: uppercase;
    color: $pink;
  }
}

.product__availability {
  &.available {
    background: url('../img/prodict_available.png') no-repeat left -2px;
    color: $green;
    padding-left: 25px;
  }
  &.not_available {
    color: red;
  }
  &.little_available {
    color: orange;
  }
}

.product__slider {
  width: 116px;
  &.not_slider_yet {
    .slick-list {
      margin-top: 55px;
      height: auto!important;
    }
  }
  .slick-list {
    border-bottom: 1px solid #eee;
  }
  .slick-arrow {
    background: url('../img/slider_arrow.svg');
    width: 35px;
    height: 35px;
    border: none;
    text-indent: -9999px;
    cursor: pointer;
    color: transparent;
    margin-left: 41px;
    &:hover {
      background: url('../img/slider_arrow_active.svg');
    }
    &:focus {
      outline: none;
    }
  }
  .slick-prev {
    transform: rotate(-90deg);
    margin-bottom: 20px;
  }
  .slick-next {
    transform: rotate(90deg);
    margin-top: 20px;
  }
}

.product__slide {
  height: 115px!important;
  width: 115px !important;
  cursor: pointer;
  border: 1px solid #eee!important;
  border-bottom: 0!important;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
  &:hover {
    border-color: $pink!important;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      margin: auto;
      background: $pink;
    }
  }
  &:focus {
    outline: none;
  }
  .product__img {
    max-width: 80%;
    height: auto;
    margin: auto;
    display: block;
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.product__view {
  padding-bottom: 50px;

    &.sale_card {
      @media (max-width: 639px) {
        .img__popup {
          min-height: 401px;
          display: block;
          margin-top: 15px;
        }
      }
    }

  .product__img_wrapper {
    position: relative;
    height: 450px;
  }
  .product__img,
  .img__popup {
    &:focus {
      outline: none;
    }
  }
  .img__popup {
    .product__img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      padding-left: 30px;
      box-sizing: border-box;
    }
  }
  .product__price_wrapper {
    background: url('../img/product__price_bg.png') no-repeat 15px center;
    min-height: 76px;
    box-sizing: border-box;
    padding-left: 46px;
    padding-top: 11px;
    &.has_discount {
      padding-top: 18px;
    }
  }
  .product__price {
    color: $white;
    font-family: 'geometria_heavy', sans-serif;
    font-weight: 700;
    font-size: 18px;
    display: block;
    margin-bottom: 3px;
    > span {
      color: $white;
      font-family: 'geometria_heavy', sans-serif;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 3px;
    }
    &.discount {
      font-family: 'geometria_regular', sans-serif;
      text-decoration: line-through;
    }
  }
}

.product__view {
  &.sale_card {
    .badges__wrapper {
      &.left {
        left: 15px;
      }
    }
    .img__popup {
      .product__img {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

.product__tabs_heading {
  margin: 0;
}

.product__tabs_content_wrapper {
  margin: 20px 0 0;
}

.product__tabs_title {
  display: inline-block;
  text-transform: uppercase;
  font-family: 'geometria_bold', sans-serif!important;
  font-size: 16px;
  cursor: pointer;
  padding: 15px;
  color: $pink;
  float: left;
  &:not(.active) {
    border-bottom: 1px solid $pink;
  }
  &.active {
    color: $black;
    &:first-child {
      border-right: 1px solid $pink;
    }
    &:not(:first-child):not(:last-child) {
      border-left: 1px solid $pink;
      border-right: 1px solid $pink;
    }
    &:last-child {
      border-left: 1px solid $pink;
    }
  }
}

.bonus_account_text {
  font-family: 'geometria_medium', sans-serif;
  text-align: right;
  padding-right: 15px;
  position: relative;
  line-height: 24px;
  &::after {
    content: '';
    width: 1px;
    height: 53px;
    background: $pink;
    position: absolute;
    right: 0;
    top: -3px;
  }
  .bonus_sum {
    font-family: 'geometria_heavy', sans-serif;
    font-weight: 900;
    font-size: 18px;
    color: #32a3b7;
  }
  .bonus_account {
    color: #ea5798;
    text-decoration: underline;
  }
}

.product__rating {
  display: inline-block;
  .rating-stars ul > li.star > i.fa {
    font-size: 16px;
  }
  .rating-stars ul > li.star {
    width: 17px;
  }
}

.product__testimonials {
  @extend .product_card__testimonials;
  margin-left: 10px;
}

.product__social {
  margin-left: 10px;
  position: relative;
  top: 6px;
}

.social_networks {
  display: inline-block;
  &.mobile {
    float: right;
    @media (max-width: 639px) {
      float: none;
      margin-top: 15px;
    }
  }
}

.social_networks__list {
  margin: 0;
  display: inline-block;
}

.social_networks__item {
  display: inline-block;
  &:not(:last-child) {
    margin-right: 2px;
  }
}

.social_networks__link {
  display: block;
  width: 24px;
  height: 24px;
  &.facebook {
    background: url('../img/social_facebook.svg') no-repeat;
  }
  &.google_plus {
    background: url('../img/social_google_plus.svg') no-repeat;
  }
  &.twitter {
    background: url('../img/social_twitter.svg') no-repeat;
  }
  &.telegram {
    background: url('../img/social_telegram.svg') no-repeat;
  }
}

.product__option_label {
  @include body_text;
  font-size: 12px;
  border: 1px solid #cccccc;
  padding: 7px 10px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 10px;
}

/*.product__option_input[data-count="0"] + .product__option_label {
  color: #cccccc;
  background: #fefefe;
  pointer-events: none;
}*/

.product__option_input:checked + .product__option_label:not(.color_opt) {
  border: 1px solid $pink;
}

.product__option_label.little_available {
  background: #ddd;
}

/*.product__option_input:checked + .product__option_label.little_available:not(.color_opt) {
  background: #ccc;
}*/

.color_opt {
  position: relative;
  padding: 2px;
  > img {
    width: 100%;
    height: 100%;
  }
  &.little_available {
    &::before {
      content: '';
      display: block;
      width: 28px;
      height: 2px;
      background: #ddd;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: -4px;
      top: 9px;
      z-index: 2;
    }
    > img {
      opacity: .5;
    }
  }
}

.product__option_input:checked + .color_opt {
  border: 1px solid $pink;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    background: $pink;
    z-index: 2;
    display: block;
  }
  &::before {
    top: 10px;
    left: 4px;
    transform: rotate(45deg);
    width: 6px;
  }
  &::after {
    top: 9px;
    left: 7px;
    transform: rotate(-45deg);
    width: 10px;
  }
}

.product__option_input {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

.product__add_to_cart {
  //margin-top: 25px;
  //border: none;
  text-align: center;
  width: 160px;
  height: 45px;
  font-size: 18px;
  cursor: pointer;
  padding-top: 0;
  display: inline-block;
  float: left;

  background-color: $pink;
  border: 1px solid transparent;
  color: $white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: transparent;
    color: $pink;
    border-color: $pink;
  }
}

.wish_list__btn {
  width: 214px;
  height: 45px;
  display: inline-block;
  border: 1px solid $pink;
  box-sizing: border-box;
  text-align: center;
  padding-top: 12px;
  float: right;

}

.wish_list__btn[data-status="1"] {
  .wish_list__text {
    background: url('../img/heart full.png') no-repeat left center;
  }
}

.wish_list__text {
  padding-left: 30px;
  background: url('../img/wishlist.png') no-repeat left center;
}

.product__advantages {
  text-align: center;
}

.product__advantages_list {
  margin: 0;
  text-align: center;
}

.product__advantages_text {
  @extend .bonus_account;
  color: #ea5798;
  text-decoration: underline;
  display: inline-block;
  padding-left: 30px;
  cursor: pointer;
}

.product__advantages_item {
  &:nth-child(1) {
    text-align: left;
    .product__advantages_text {
      background: url('../img/product_advantage_1.png') no-repeat left;
    }
  }
  &:nth-child(2) {
    .product__advantages_text {
      background: url('../img/product_advantage_2.png') no-repeat left;
    }
  }
  &:nth-child(3) {
    text-align: right;
    .product__advantages_text {
      background: url('../img/product_advantage_3.png') no-repeat left;
    }
  }
}

.product__attr_title_wrapper {
  padding-right: 5px;
}

.product__attr_val_wrapper {
  padding-left: 0;
}

.product__tabs_content[data-attr="tab_content#2"] {
  > .row:first-child {
      .product__attr_val,
      .product__attr_title {
        margin-top: 15px;
      }
   }
}

.product__attr_val,
.product__attr_title {
  margin-top: 0;
  margin-bottom: 5px;
}

.testimonial {
  .rating-stars {
    display: inline-block;
  }
}

.leave__testimonial {
  .rating-stars ul > li.star > i.fa {
    font-size: 18px;
  }
  .rating-stars ul > li.star {
    width: 18px;
  }
}

.testimonial__user_name {
  font-family: 'geometria_medium', sans-serif;
}

.testimonial__date {
  color: $pink;
  margin-right: 7px;
}

.testimonials__title {
  font-size: 24px;
}

.testimonials__input {
  height: 34px;
  border: 1px solid #e4e4e4;
  background-color: $white;
  display: block;
  width: 100%;
  padding-left: 10px;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: 'geometria_regular_italic', sans-serif;
  font-style: italic;
  margin-bottom: 15px;
  box-sizing: border-box;
  resize: none;
}

.testimonials__textarea {
  @extend .testimonials__input;
  padding-top: 8px;
  height: 144px;
}

.testimonials__submit_button {
  width: 160px;
  height: 43px;
  border: none;
  padding-top: 10px;
  margin: 30px auto 0;
  font-size: 18px;
}

.error-box {
  display: block;
  color: red;
}

.error + .error-box {
    margin-bottom: 15px;
}

.not_error + .error-box {
    margin-bottom: 15px;
}
/*product page ends*/

/*static page*/
.static_page {
  padding-bottom: 50px;
  h3 {
    margin: 0;
  }
}
/*static page ends*/

/*related products*/
.related__title {
  font-size: 18px;
  text-transform: none;
}

.related__products {
  .owl-stage-outer {
    padding: 7px 0 7px;
  }
  //margin-bottom: 30px;
  .owl-nav {
    bottom: 0;
    .owl-prev {
      left: -80px;
      position: relative;
      margin: 0;
    }
    .owl-next {
      right: -80px;
      position: relative;
      margin: 0;
    }
  }
}

.leave__testimonial {
  .rating-stars {
    margin-bottom: 20px;
  }
}

/*related products ends*/

/*news page*/
.news__title {
  margin: 0;
}

.single_post {
  .news__title {
    font-size: 36px;
    @media (max-width: 639px) {
      font-size: 20px;
    }
    font-family: 'geometria_bold', sans-serif;
    color: $black;
    text-transform: uppercase;
  }
}

.single_article__inner_wrapper {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.single_article__row {
  padding: 0;
}

.news__underline {
  margin-bottom: 35px;
}

.single_article__image_wrapper {
  height: 390px;
  overflow: hidden;
  position: relative;
}

.single_article__image {
  width: auto;
  height: auto;
  display: block;
  margin: auto;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.single_article__title {
  @extend .article_preview__title;
  margin: 0 0 10px;
  text-align: left;
  padding: 0;
}

.single_article__date {
  @extend .article_preview__date;
  text-align: right;
  display: block;
}

.single_article__readmore {
  @extend .sale_block__btn;
  .btn__text {
    @extend .btn__text;
  }
  .btn__arrow {
    @extend .btn__arrow;
  }
  &:hover {
    .btn__arrow {
      background: #fff;
      &::before,
      &::after {
        background: #fff;
      }
    }
    .btn__text {
      color: #fff;
    }
    background: #ea5798;
  }
}

.single_article__short_desc {
  margin-bottom: 30px;
  line-height: 20px;
}

.single_article__content {
  padding-left: 30px!important;
  padding-right: 30px!important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.single_article {
  margin-bottom: 30px;
}

.social__list {
  //@extend .footer__social;
  text-align: right;
}

.social__item {
  @extend .footer__social_item;
}

.social__link {
  @extend .footer__social_link;
}

.bank_logo__list {
  display: block;
  text-align: center;
}

.bank_logo__item{
  display: inline-block;
}

.bank_logo__link {
  display: inline-block;
  float: left;
  margin: 0 5px;
  position: relative;
  &.mcsc {
    background: url('../img/mcsc.png') no-repeat;
    background-size: contain;
    width: 44px;
    height: 16px;
  }
  &.vbv {
    background: url('../img/vbv.png') no-repeat;
    background-size: contain;
    width: 40px;
    height: 18px;
  }
  &.pumb {
    background: url('../img/pumb.png') no-repeat;
    background-size: contain;
    width: 26px;
    height: 25px;
    top: 3px;
  }
}

.single_post__text {
  margin-bottom: 50px;
}

.post__bottom_row {
  margin-bottom: 40px;
}

.latest_news__title {
  font-size: 14px;
  font-family: 'geometria_medium', sans-serif;
  color: $black;
  margin-bottom: 20px;
}

.latest_news__text_wrapper {
  padding-left: 0;
}

.latest_news__article {
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.latest_news__article {
  .latest_news__date {
    text-align: left;
    display: block;
    color: #ea5798;
    margin-left: 20px;
    margin-right: 20px;
  }
  &:nth-child(odd) {
    background: $white;
  }
  &:nth-child(even) {
    background: #f9f9f9;
  }
}

.latest_news__text {
  margin: 10px 0;
}
/*news page ends*/

/*article page*/
.articles,
.news {
  .news__title {
    font-size: 36px;
    @media (max-width: 639px) {
      font-size: 20px;
    }
    font-family: 'geometria_bold', sans-serif;
    color: $black;
    text-transform: uppercase;
  }
}

.articles__catalog {
  margin-bottom: 30px;
}
/*article page ends*/

/*single article*/
.single_post__image {
  max-width: 100%;
  width: auto;
  height: auto;
}

.single_post__date {
  @extend .single_article__date;
  text-align: left;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0;
}

.single_post__readmore {
  float: left;
  @extend .sale_block__btn;
  .btn__text {
    @extend .btn__text;
  }
  .btn__arrow {
    @extend .btn__arrow;
  }
  &.prev {
    margin-right: 20px;
    .btn__text {
      padding-left: 26px;
      padding-right: 0;
    }
    .btn__arrow {
      right: auto;
      left: 0;
      transform: rotate(180deg);
    }
  }
  &:hover {
    .btn__arrow {
      background: #fff;
      &::before,
      &::after {
        background: #fff;
      }
    }
    .btn__text {
      color: #fff;
    }
    background: #ea5798;
  }
}
/*single article ends*/

/*searched products page*/
.found_products__text {
  display: inline-block;
  margin-bottom: 40px;
}

.not_found__wrapper {
  max-width: 540px;
  border: 1px solid $pink;
  padding: 30px;
  margin-bottom: 50px;
}

.not_found__text {
  margin: 0 0 10px;
}

.not_found__phone {
  margin-top: 15px;
  display: block;
  font-family: 'geometria_bold', sans-serif;
  color: $pink;
  font-size: 24px;
  padding-left: 35px;
  background: url('../img/phone_call.png') no-repeat left center;
}
/*searched products page ends*/

/*brands page*/
.brands_page__brand_link {
  @include body_text;
  display: block;
  font-size: 16px;
  text-decoration: underline;
  margin-bottom: 5px;
  &:hover {
    color: $pink;
    text-decoration: underline;
  }
}

.brand_page__letter_big {
  font-size: 48px;
  color: $pink;
  font-family: 'geometria_bold', sans-serif;
  margin: 30px 0 5px;
}

.brands_page {
  padding-bottom: 50px;
  .section__title {
    font-size: 36px;
    margin: 0;
    @media (max-width: 639px) {
      font-size: 20px;
    }
    font-family: 'geometria_bold', sans-serif;
    color: $black;
    text-transform: uppercase;
  }
  .section_title__underline {
     margin-bottom: 0;
  }
}
/*brands page ends*/

/*cart page*/
.cart__product_column {
  text-align: center;
  background: #f9f9f9;
  height: 62px;
}

.cart__product_inner_col {
  -ms-flex: 0 0 14.285714%;
  flex: 0 0 14.285714%;
  max-width: 14.285714%;
}

.cart__product_option_wrapper:not(:last-child) {
  margin-bottom: 15px;
}

.cart__product_option_img {
  position: relative;
  top: 7px;
}

.cart__product_heading {
  font-size: 14px;
  font-family: 'geometria_medium', sans-serif;
  color: $black;
  text-transform: uppercase;
  padding-top: 22px;
  display: block;
  font-weight: 500;
}

.cart__outer {
  position: relative;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  &.loading {
    pointer-events: none;
    &::before {
      content: '';
      background: url('../img/grid.svg') rgba(255, 255, 255, 0.85);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-repeat: no-repeat;
      background-size: 100px;
      background-position: center;
    }
  }
}

.cart__title {
  font-size: 36px;
  margin: 0;
  @media (max-width: 639px) {
    font-size: 20px;
  }
  font-family: 'geometria_bold', sans-serif;
  color: $black;
  text-transform: uppercase;
}

.cart__empty_text {
  margin-bottom: 30px;
}

.cart__product {
  text-align: center;
  background: $white;
  &:not(:nth-last-child(2)) {
    border-bottom: 1px solid #eee;
  }
}

.total_bonuses {
  font-size: 18px;
  font-family: 'geometria_bold', sans-serif;
  color: $pink;
  font-weight: 700;
  text-align: center;
}

.total_price {
  font-size: 18px;
  font-family: 'geometria_bold', sans-serif;
  color: $black;
  font-weight: 700;
  text-align: center;
}

.cart__product_img {
  max-width: 100%;
}

.cart__product_quantity {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.total_row {
  background: #f9f9f9;
}

.cart__plus {
  position: absolute;
  top: 7px;
  right: 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #ea5799 transparent;
  z-index: 3;
  cursor: pointer;
}

.cart__minus {
  position: absolute;
  bottom: 7px;
  right: 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #ea5799 transparent transparent transparent;
  z-index: 3;
  cursor: pointer;
}

.cart__product_quantity_input {
  max-width: 100%;
  box-sizing: border-box;
  width: 65px;
  height: 32px;
  text-align: center;
  padding-right: 11px;
  cursor: pointer;
  border: 1px solid #ccc;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.cart__product_price {
  width: 70%;
  display: inline-block;
  float: left;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}

.cart__product_bonuses {
  font-size: 14px;
  font-family: 'geometria_bold', sans-serif;
  color: $pink;
}

.cart__product_title_link {
  font-size: 14px;
  font-family: 'geometria_medium', sans-serif;
  color: #666;
  line-height: 20px;
}

.cart__product_remove {
  width: 30%;
  display: inline-block;
  float: left;
  margin-top: 13px;
}

.cart__product_remove_btn {
  width: 20px;
  height: 20px;
  display: block;
  border: none;
  cursor: pointer;
  position: relative;
  background: transparent;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    display: block;
    width: 20px;
    height: 1px;
    background: red;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.cart__top {
  z-index: 3;
}

.checkout__list_wrapper {
  background: $white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 51%;
    height: 94px;
    width: 1px;
    background: #e4e4e4;
  }
}

.checkout__text {
  font-size: 18px;
  font-family: 'geometria_regular', sans-serif;
  color: $black;
  padding: 30px;
  text-align: left;
  &:nth-child(1) {
    padding-bottom: 0;
  }
}

.checkout__val {
  font-size: 16px;
  font-family: 'geometria_regular', sans-serif;
  color: #ff4949;
  padding: 30px;
  text-align: left;
  &:nth-child(2) {
    padding-bottom: 0;
  }
}

#cart {
  .checkout__text,
  .checkout__val {
    padding-left: 20px;
  }
}

.checkout__total_text {
  font-size: 24px;
  font-family: 'geometria_bold', sans-serif;
  color: $black;
  font-weight: 700;
}

#sticky_block {
  .checkout__total_text {
    padding-left: 20px;
    padding-top: 14px;
  }
  .checkout__total_val {
    padding-right: 20px;
    padding-top: 14px;
  }
}

.checkout__bonus_total_text {
  font-size: 16px;
  margin-bottom: -5px;
  margin-top: 25px;
}

.checkout__bonus_total_val {
  color: $pink;
  font-size: 16px;
}

.checkout__total_val {
  font-size: 24px;
  font-family: 'geometria_bold', sans-serif;
  color: $pink;
  font-weight: 700;
}

.checkout__list_wrapper {
  text-align: center;
}

.checkout__btn {
  display: block;
  width: 190px;
  height: 45px;
  background: #73c93f;
  color: $white;
  text-align: center;
  font-size: 18px;
  font-family: 'geometria_medium', sans-serif;
  padding-top: 11px;
  box-sizing: border-box;
  margin: 0 auto 40px;
  letter-spacing: 1px;

  background-color: #73c93f;
  border: 1px solid transparent;
  color: $white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: transparent;
    color: #73c93f;
    border-color: #73c93f;
  }
}

.checkout__total_text {
  text-align: left;
}

.checkout__total_val {
  text-align: right;
}

.checkout__sum_to_pay_col {
  background: #f9f9f9;
  height: 48px;
}

#sticky_block {
  position: static;
  @media (min-width: 800px) {
    &.sticky {
      position: fixed;
      top: 0;
      //right: 0;
      //right: 367px;
      width: 370px;
      //height: 201px;
      background: $white;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      z-index: 999;
    }
  }
}

.unauthorized_user__tabs {
  margin: 30px 0 30px;
  text-align: center;
}

.unauthorized_user__tabs_item {
  width: 190px;
  height: 45px;
  display: inline-block;
  font-size: 16px;
  font-family: 'geometria_medium', sans-serif;
  border: 1px solid #ccc;
  text-align: center;
  padding-top: 11px;
  box-sizing: border-box;
  margin: 0 13px;
  cursor: pointer;
  &.active {
    border: 1px solid $pink;
    color: $pink;
  }
}

label.bay_bay_theme {
  @include body_text;
  &:not(.radio_label){
    display: block
  };
  &.radio_label {
    font-family: 'geometria_medium', sans-serif;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 15px;
  }
}

input[type="radio"]:checked + label.radio_label {
  color: $pink;
}

input.bay_bay_theme {
  @extend .profile_menu__input;
  width: 100%;
}

label.bay_bay_theme {
  margin-bottom: 5px;
}

select.bay_bay_theme {
  @extend .profile_menu__input;
  width: 100%;
}

.payment__radiobox {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.cart__payment_comment_wrapper {
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
}

.cart__payment_comment {
  min-height: 100px;
  max-height: 350px;
}

.cart__delivery_title {
  margin-top: 0;
}

#choose_department {
  cursor: pointer;
}

.nova_pochta__city_input_wrapper {
  position: relative;
  .nova_pochta__autocomplete_wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    background: $white;
    display: block;
    top: 20px;
    left: 0;
    padding: 10px 15px;
    line-height: 25px;
    letter-spacing: 1px;
    font-size: 13px;
    font-family: 'geometria_regular_italic', sans-serif;
    font-style: italic;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    z-index: 10;
    cursor: pointer;
  }
}

.unauthorized_user__notify_text {
  font-size: 12px;
  color: $pink;
  margin-left: 7px;
  text-decoration: underline;
  //margin-top: 7px;
  display: inline-block;
}

.required_field {
  color: $pink;
  margin-left: 5px;
}

.cart__register_btn {
  width: 190px;
  height: 45px;
  font-size: 18px;
  padding-top: 11px;
  margin: 15px auto 30px;
  letter-spacing: 1px;
}

.payment_column__text {
  @include body_text;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100px;
  display: block;
  margin: auto;
  height: 18px;
  pointer-events: none;
  max-width: 110px;
}

.cart__payment_column{
  &:nth-child(3) {
    .payment_column__text {
      height: 36px;
    }
  }
}

.payment_column__row {
  &.second_row {
    .cart__payment_column {
      &:nth-child(1) {
        .payment_column__text {
          height: 36px;
        }
      }
    }
  }
}

.payment_column__inner {
  height: 70px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  display: block;
  &.card {
    background: url('../img/payment_1.png') no-repeat left 30px center $white;
  }
  &.bank {
    background: url('../img/payment_2.png') no-repeat left 30px center $white;
  }
  &.cash {
    background: url('../img/payment_3.png') no-repeat left 30px center $white;
  }
  &.privat24 {
    background: url('../img/payment_4.png') no-repeat left 30px center $white;
  }
}

.payment_column__row {
  &.first_row {
    margin-bottom: 30px;
  }
}

.payment__radiobox:checked + .payment_column__inner {
  border: 1px solid $pink;
}

.cart__payment_comment {
  resize: vertical;
  width: 100%;
  border: 1px solid #e4e4e4;
  margin-bottom: 50px;
  max-width: 100%;
  box-sizing: border-box;
}

.unauthorized_user__tabs_content {
  display: none;
  &.active {
    display: block;
  }
}

.cart__recover_password,
.cart__recover_password:hover,
.cart__recover_password:focus,
.cart__recover_password:visited {
  @extend .unauthorized_user__notify_text;
  font-size: 18px;
  display: block;
  text-align: center;
  margin: 0;
  font-family: 'geometria_regular', sans-serif;
}

.cart__login_btn {
  @extend .cart__register_btn;
  margin-bottom: 10px;
}

.cart__pay_with_bonuses {
  border: 1px solid $pink;
  padding: 20px 30px 10px 30px;
  margin-bottom: 30px;
}

.cart__pay_with_bonuses_wrapper {
  margin-top: 30px;
  //padding: 0;
}

.pay_with_bonuses__title {
  margin: 0;
}

.pay_with_bonuses__bonuses_total {
  font-size: 16px;
  margin-bottom: 0;
}

.pay_with_bonuses__bonuses_total_val {
  font-size: 18px;
  color: $pink;
  margin-top: 5px;
  font-weight: 500;
}

.pay_with_bonuses__bonus_input_wrapper {
  text-align: center;
}

.pay_with_bonuses__bonus_input_inner {
  display: inline-block;
}

.pay_with_bonuses__bonus_input_text {
  margin-bottom: 5px;
}

.pay_with_bonuses__bonus_input {
  padding: 0 15px;
  text-align: center;
  width: 110px;
  height: 33px;
  float: left;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-right: none;
}

.pay_with_bonuses__bonus_input_btn {

  &.disabled {
    background: #ccc;
    cursor: no-drop;
  }

  width: 131px;
  height: 33px;
  float: left;

  background-color: $pink;
  border: 1px solid transparent;
  color: $white;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover:not(.disabled) {
    background-color: transparent;
    color: $pink;
    border-color: $pink;
  }

}

.error_box {
  @include body_text;
  color: $pink;
  margin-bottom: 10px;
  display: block;
}

.radio_box_baybay__label {
  @extend .user_form__label;
  display: block;
  margin-bottom: 20px;
}

.nova_pochta__inputs_wrapper {
  display: none;
}

.adress__inputs_wrapper {
  display: none;
}

input#nova_poshta:checked ~ .nova_pochta__inputs_wrapper {
  display: block;
}

input#delivery_to_adress:checked ~ .adress__inputs_wrapper {
  display: block;
}

input#delivery_to_nova_poshta_department:checked ~ .ukr_pochta__inputs_wrapper {
  display: block;
}

#products_in_cart_text {
  position: absolute;
  right: -2px;
  top: -5px;
  border: 1px solid $pink;
  background: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 9px;
}
/*cart page ends*/

/*order complete page*/
.thank_you__title {
  margin-top: 0;
}
.thank_you__text {
  font-family: 'geometria_bold', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}
.thank_you__order {
  @include body_text;
  font-family: 'geometria_medium', sans-serif;
  text-transform: none;
  font-weight: 500;
}
.thank_you__value {
  @include body_text;
  color: $pink;
  font-family: 'geometria_medium', sans-serif;
  text-transform: none;
  font-weight: 500;
}
.thank_you__button {
  height: 45px;
  font-size: 18px;
  padding-top: 11px;
  max-width: 300px;
  margin: 30px auto 50px;
}
/*order complete page ends*/

/*reset pass view*/
.reset_pass__btn {
  height: 45px;
  min-width: 260px;
  font-size: 18px;
  cursor: pointer;
  padding-top: 0;
}
/*reset pass view ends*/

/*personal cabinet*/
@media (min-width: 1200px) {
  .user_profile {
    .product_card__outer_wrapper {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.product_card__close_btn {
  display: none;
}

.user_profile {
  .product_card__close_btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 10;
    background: rgba(255,255,255,.65);
    &::before,
    &::after {
      content: '';
      width: 24px;
      height: 2px;
      background: $pink;
      position: absolute;
      left: 0;
      right: 0;
      top: 11px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

.user_profile__title {
  margin-top: 0;
}

.user_profile__list {
  margin-top: 0;
  margin-bottom: 5px;
}

.user_profile__item {
  &.my_profile {
    background: url('../img/profile_tab_1.png') left 50px center no-repeat;
  }
  &.my_form {
    background: url('../img/profile_tab_2.png') left 55px center no-repeat;
  }
  &.my_orders {
    background: url('../img/profile_tab_3.png') left 40px center no-repeat;
  }
  &.my_wish_list {
    background: url('../img/profile_tab_4.png') left 35px center no-repeat;
  }
  &.bay_bay_club {
    background: url('../img/profile_tab_5.png') left 35px center no-repeat;
  }
  &.quit {
    background: url('../img/profile_tab_6.png') left 55px center no-repeat;
  }
  &.active {
    .user_profile__link {
      border: 1px solid $pink;
    }
  }
}

.user_profile__link {
  font-family: 'geometria_medium', sans-serif;
  font-size: 15px;
  color: $pink;
  text-align: center;
  display: block;
  height: 61px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding-top: 21px;
  box-sizing: border-box;
  padding-left: 30px;
  border: 1px solid transparent;
}

.user_profile__button {
  width: 190px;
  height: 45px;
  border: none;
  padding-top: 0;
  cursor: pointer;
  font-size: 18px;
  margin: 20px auto 40px;
}

.user_form__order_text {
  &.order_text__big {
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'geometria_bold', sans-serif;
    font-weight: 700;
  }
  &.order_text__left {
    text-align: left;
  }
  &.order_text__right {
    text-align: right;
  }
  &.order_text__total {
    //font-size: 16px;
    text-transform: uppercase;
    font-family: 'geometria_bold', sans-serif;
    font-weight: 700;
    color: $pink;
  }
  &.order_text__total_val {
    //font-size: 16px;
    text-transform: uppercase;
    font-family: 'geometria_bold', sans-serif;
    font-weight: 700;
    color: $pink;
  }
}

.user_form__product_status {
  font-size: 15px;
  text-transform: uppercase;
  font-family: 'geometria_bold', sans-serif;
  font-weight: 700;
  color: $pink;
  text-align: right;
}

.order_underline {
  border-top: 1px solid #ccc;
  border-bottom: 0;
  margin-bottom: 50px;
}

@media (max-width: 1199px) {
  .user_form__column_03,
  .user_form__column_05,
  .user_form__column_06 {
    flex: 0 0 15%;
    max-width: 15%;
    //margin-left: 70%;
  }
  .user_form__column_07,
  .user_form__column_08,
  .user_form__column_09,
  .user_form__column_10,
  .user_form__column_11,
  .user_form__column_12 {
    flex: 0 0 15%;
    max-width: 15%;
  }
  .user_form__column_07,
  .user_form__column_09,
  .user_form__column_11{
    margin-left: 70%;
  }
  .user_form__column_04 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .user_form__column_01 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .user_form__column_02 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .user_profile__item {
    &.my_profile {
      background: url('../img/profile_tab_1.png') center top 6px no-repeat;
    }
    &.my_form {
      background: url('../img/profile_tab_2.png') center top 6px no-repeat;
    }
    &.my_orders {
      background: url('../img/profile_tab_3.png') center top 6px no-repeat;
    }
    &.my_wish_list {
      background: url('../img/profile_tab_4.png') center top 11px no-repeat;
    }
    &.bay_bay_club {
      background: url('../img/profile_tab_5.png') center top 6px no-repeat;
    }
    &.quit {
      background: url('../img/profile_tab_6.png') center top 6px no-repeat;
    }
    &.active {
      .user_profile__link {
        border: 1px solid $pink;
      }
    }
  }
  .user_profile__link {
    padding-top: 38px;
    padding-left: 0;
    font-size: 13px;
  }
}
@media (max-width: 799px) {
  .user_form__column_01,
  .user_form__column_02,
  .user_form__column_03,
  .user_form__column_04,
  .user_form__column_05,
  .user_form__column_06,
  .user_form__column_07,
  .user_form__column_08,
  .user_form__column_09,
  .user_form__column_10,
  .user_form__column_11,
  .user_form__column_12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .user_form__order_text {
    &.order_text__right {
      text-align: left;
    }
  }
  .user_form__product_status {
    text-align: left;
  }
  .user_profile__link {
    text-indent: -9999px;
    color: transparent;
    padding-top: 0;
  }
  .user_profile__item {
    &.my_profile {
      background: url('../img/profile_tab_1.png') center center no-repeat;
    }
    &.my_form {
      background: url('../img/profile_tab_2.png') center center no-repeat;
    }
    &.my_orders {
      background: url('../img/profile_tab_3.png') center center no-repeat;
    }
    &.my_wish_list {
      background: url('../img/profile_tab_4.png') center center no-repeat;
    }
    &.bay_bay_club {
      background: url('../img/profile_tab_5.png') center center no-repeat;
    }
    &.quit {
      background: url('../img/profile_tab_6.png') center center no-repeat;
    }
  }
}
@media (max-width: 639px) {
  .user_profile__item {
    flex: 0 0 100%;
    max-width: 100%;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  #params-form {
    .chosen-container {
      max-width: 100%;
    }
  }
}
/*personal cabinet ends*/

/*contacts*/

.contacts {
  .section__title {
    font-size: 36px;
    @media (max-width: 639px) {
      font-size: 20px;
    }
    font-family: 'geometria_bold', sans-serif;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.contacts__tabs_content_item {
  display: none;
  &.active {
    display: block;
  }
}

.contacts__tabs_heading_item {
  &.active {
    .contacts__tabs_heading_item_inner {
      border: 1px solid $pink;
    }
  }
}

.contacts__tabs_heading_item_text {
  position: absolute;
  left: 70px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 114px;
  height: 38px;
  &::before {
    content: '';
    position: absolute;
    left: -62px;
    top: 0;
    bottom: 0;
    display: block;
    width: 34px;
    height: 42px;
  }
  &.consult {
    &::before {
      background: url('../img/contact_us_01.png') no-repeat left center;
      width: 32px;
    }
  }
  &.cosmet {
    &::before {
      background: url('../img/contact_us_02.png') no-repeat left center;
    }
  }
  &.visage {
    &::before {
      background: url('../img/contact_us_03.png') no-repeat left center;
    }
  }
}

#contact_us_form {
  .error-box {
    display: block;
    &.isVisible {
      margin-bottom: 15px;
    }
  }
}

.contacts__tabs_heading_item_inner {
  height: 70px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  display: block;
  text-align: left;
  padding-top: 15px;
  box-sizing: border-box;
}

.contacts__tabs_heading {
  margin-bottom: 30px;
}

.contacts__list {
  padding: 34px;
  margin: 16px 0 0;
  border: 1px solid $pink;
}

.contacts__item {
  @include body_text;
  padding-left: 25px;
  background-repeat: no-repeat;
  background-position: left center;
  &:nth-child(1) {
    background-image: url('../img/contacts_icon_1.png');
  }
  &:nth-child(2) {
    background-image: url('../img/contacts_icon_2.png');
  }
  &:nth-child(3) {
    background-image: url('../img/contacts_icon_3.png');
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.contacts__contact_us_title {
  font-family: 'geometria_medium', sans-serif;
  font-size: 24px;
  text-align: center;
  color: $black;
  text-transform: none;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

@media (max-width: 799px) {
  .contacts__tabs_heading_item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contacts__tabs_heading_item_inner {
    max-width: 226px;
    margin: 0 auto 30px;
  }
}

.contacts__input {
  @extend .profile_menu__input;
  width: 100%;
}

.contacts__textarea {
  resize: none;
  min-height: 87px;
  //max-height: 276px;
  padding: 10px;
}

.contacts__btn {
  max-width: 160px;
  height: 45px;
  width: 100%;
  border: none;
  //margin: 20px auto 50px;
  float: left;
  font-family: 'geometria_medium', sans-serif;
  font-size: 18px;
  padding-top: 0;
  cursor: pointer;
}

@media (max-width: 639px) {
  .contacts__btn {
    float: none;
    margin: 30px auto;
  }
  label[for="file-5"] {

  }
  .upload_file_wrapper {
    text-align: center;
    > .inputfile-5 + label {
      text-align: center;
      float: none;
    }
  }
}

@media (max-width: 1199px) {

  .contacts__left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contacts__right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contacts__list {
    margin-bottom: 30px;
  }

}
/*contacts ends*/

/*hub page*/
.hub__cat_title {
  margin-bottom: 5px;
  font-family: 'geometria_bold', sans-serif;
  a {
    color: $pink;
    font-size: 20px;
  }
}
.hub__sub_title_lvl_1 {
  font-family: 'geometria_bold', sans-serif;
  a {
    font-size: 18px;
  }
}
.hub__sub_title_lvl_2 {
  font-family: 'geometria_bold', sans-serif;
  a {
    font-size: 16px;
  }
}
.hub__sub_title_lvl_3 {
  margin: 0 0 5px;
  a {
    font-size: 14px;
  }
}
.hub__sub_cat_lvl_1 {
  padding-left: 10px;
}
.hub__sub_cat_lvl_2 {
  padding-left: 10px;
}
.hub__sub_cat_lvl_3 {
  padding-left: 10px;
}
.hub_page {
  padding-bottom: 30px;
  .section__title {
    font-size: 36px;
    margin: 0;
    @media (max-width: 639px) {
      font-size: 20px;
    }
    font-family: 'geometria_bold', sans-serif;
    color: $black;
    text-transform: uppercase;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .hub__main_cat {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
/*hub page ends*/

/*modals*/
.pumb_terms_of_use {
  h5 {
    color: $black;
  }
}

.add_to_cart__modal {
  /*min-height: 400px;*/
  outline: none;
}

.modal__img {
  &.add_to_cart {
    display: block;
    margin: 30px auto;
  }
  &.registration_complete {
    margin: 30px auto;
    display: block;
  }
}

.modal__title {
  .product_name {
    @extend h3;
    font-size: 28px;
    line-height: 32px;
    @media(max-width: 639px){
      font-size: 14px;
      line-height: 16px;
    }
    color: $pink;
  }
  &.add_to_cart {
    font-size: 28px;
    line-height: 32px;
    padding: 0 45px;
    @media(max-width: 639px){
      font-size: 14px;
      line-height: 16px;
    }
  }
  &.registration_complete {
    text-transform: none;
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    padding: 0 45px;
    @media(max-width: 639px){
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.modal__add_to_cart_btn {
  margin-bottom: 30px!important;
  .modal_btn {
    height: 45px;
    font-size: 18px;
    padding-top: 11px;
    &.countinue_shopping {

      background-color: #ccc;
      border: 1px solid transparent;
      color: $white;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: transparent;
        color: #ccc;
        border: 1px solid #ccc;
      }

    }
    /*&.view_cart {

    }*/
    &.registration_complete_btn {
      max-width: 235px;
      height: 45px;
      padding-top: 11px;
      font-size: 18px;
      margin: 0 auto 30px;
    }
  }
}

.modal__registration_complete_btn {
  .modal_btn {
    height: 45px;
    font-size: 18px;
    padding-top: 11px;
    &.registration_complete_btn {
      max-width: 235px;
      height: 45px;
      padding-top: 11px;
      font-size: 18px;
      margin: 0 auto 30px;
    }
  }
}

.reveal {
  box-sizing: border-box;
  .close_btn {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: 10px;
    top: 14px;
    background: none;
    border: none;
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -2px;
      top: 7px;
      width: 20px;
      height: 1px;
      background: #ff4949;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}
.modal__thanks_for_purchase_text {
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .reveal {
    width: 100%;
  }

  .modal__add_to_cart_btn {
    > .columns {
      flex: 0 0 100%;
      max-width: 100%;
      > .modal_btn {
        max-width: 320px;
        margin: 0 auto 30px;
      }
    }
  }
  .modal__title {
    &.add_to_cart {
      font-size: 18px;
      padding: 0 15px;
      .product_name {
        font-size: 18px;
      }
    }
  }
}

/*modals ends*/

/*isotope*/
.grid-item {
  width: 25%;
  padding: 0 15px;
  box-sizing: border-box
}

@media (max-width: 1199px) {
  .grid-item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .grid-item {
    width: 100%;
  }
}
/*isotope ends*/

/*user form*/
.user_form__title {
  margin-top: 20px;
}

.user_form__filter_title {
  @include body_text;
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 10px;
}

.user_form__filter_group {
  margin-bottom: 10px;
}

.label__checkbox.baybay__theme.user_form__label {
  padding-left: 25px;
  @include body_text;
  left: 0;
}

.checkbox:checked + .label__checkbox {
  &.user_form__label {
    color: $pink;
  }
}

.label__radiobox.baybay__theme.user_form__label {
  padding-left: 25px;
  @include body_text;
  left: 0;
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    width: 14px;
    height: 14px;
    display: block;
    border-radius: 50%;
    border: 1px solid #dadada;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.radiobox:checked + .label__radiobox {
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    background: $black;
    position: absolute;
    left: 4px;
    top: 4px;
  }
  &.user_form__label {
    color: $pink;
  }
}

.radiobox.baybay__theme {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.user_form__button {
  width: 190px;
  height: 45px;
  font-size: 18px;
  border: none;
  padding-top: 0;
  cursor: pointer;
  margin: 40px auto 40px;
}

.user_form__date {
  max-width: 160px;
}

.user_form__chosen_select {
  width: 100%;
}

.user_form__bonus_text {
  font-family: 'geometria_medium', sans-serif;
  font-size: 16px;
  margin-bottom: 30px;
}

/*user date of birth*/
.user_form__day_of_birth {
  max-width: 66px;
  display: inline-block;
  margin-right: 10px;
}

.user_form__month_of_birth {
  max-width: 160px;
  display: inline-block;
  margin-right: 10px;
}

.user_form__year_of_birth {
  max-width: 66px;
  display: inline-block;
  margin-right: 10px;
}
/*user form ends*/

/*pagination*/



.pagination__list {
  text-align: center;
  margin-bottom: 45px;
}

.pagination__item {
  display: inline-block;
  margin: 0 6px;
  &.active {
    .pagination__text {
      color: $pink;
    }
  }
}

.pagination__link {
  color: $black;
  font-family: 'geometria_medium', sans-serif;
  font-size: 18px;
}

.pagination__text {
  color: $black;
  font-family: 'geometria_medium', sans-serif;
  font-size: 18px;
}

.show_more_btn {
  display: block;
  margin: 20px auto 40px;
  width: 220px;
  height: 45px;
  background: $pink;
  color: $white;
  text-align: center;
  font-family: 'geometria_medium', sans-serif;
  font-size: 18px;
  padding-top: 13px;
  box-sizing: border-box;
  cursor: pointer;
}

.pagination__next,
.pagination__prev {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 10px;
  margin: 1px 3px;
}

.pagination__next_link {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent $pink;
  position: absolute;
  left: 0;
  right: 0;
}

.pagination__prev_link {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent $pink;
  position: absolute;
  left: 0;
  right: 0;
  transform: rotate(180deg);
}
/*pagination ends*/

/*checkbox styled*/
.checkbox {
  &.baybay__theme {
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
}

.category__filter_column {
  .label__checkbox {
    &.baybay__theme {
      line-height: 100%;
    }
  }
}

.label__checkbox {
  &.baybay__theme {
    background: url('../img/check_box.png') no-repeat $white;
    background-size: 14px 14px;
    display: inline-block;
    position: relative;
    margin-right: 5px;
    padding-left: 14px;
    min-height: 14px;
    //top: -6px;
    top: 2px;
    left: 3px;
    cursor: pointer;
    &.filter__label {
      padding-left: 25px;
      top: 0;
      background-position: left 2px;
      &::before {
        top: 8px;
      }
      &::after {
        top: 9px;
      }
      &.disabled {
        display: none;
        cursor: not-allowed;
        .filter__input_text,
        .filter__input_count {
          color: #ccc;
        }
      }
    }
    &::before,
    &::after {
      opacity: 0;
      transition: .3s ease-out;
      content: '';
      display: block;
      position: absolute;
      background: #565656;
    }
    &::before {
      left: 4px;
      top: 6px;
      width: 9px;
      height: 2px;
      transform: rotate(-45deg);
    }
    &::after {
      left: 2px;
      top: 7px;
      width: 4px;
      height: 2px;
      transform: rotate(45deg);
    }
  }
  &.label_has_pink_text {
    font-size: 12px;
    color: $pink;
    text-decoration: underline;
    padding-left: 24px;
    margin-left: 0;
    left: 0;
  }
}

.checkbox.baybay__theme:checked ~ .label__checkbox {
  &::before,
  &::after {
    opacity: 1;
    transition: .3s ease-out;
  }
  .filter__input_text {
    color: $pink;
  }
}
/*checkbox styled ends*/

/*form inputs validation*/
#registration-error,
#login-error{
  color: red;
  * {
    display: block;
    color: red;
    margin-bottom: 20px;
  }
}

.not_error {
  border-color: green;
}

.error {
  border-color: red;
}

.not_error + .error-box {
  color: green;
}

input:focus,
textarea:focus {
  outline: none;
}
/*form inputs validation ends*/

@import "vendor/simplebar";

/*mobile & responsive*/
@media (min-width: 960px) and (max-width: 1199px) {
  .row {
    max-width: 48rem;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .row {
    max-width: 48rem;
  }
}

/*header responsive*/
.header__menu_mobile_btn {
  display: none;
  max-width: 220px;
  height: 45px;
  margin: 0 auto;
  font-size: 18px;
  padding-top: 12px;
  cursor: pointer;
  margin-top: 15px;
}

.mobile_menu {
  display: none;
}
/*прячем подкатегории 2 уровня*/
.mobile_menu__sub_menu {
  display: none;
}
/*прячем подкатегории 3 уровня*/
.mobile_menu__sub_category_list {
  display: none;
}

.has_submenu {
  position: relative;
}

.mobile_menu__sub_menu_item {
  padding: 10px 0 0;
}

.mobile_menu__sub_category_item {
  margin-bottom: 5px;
}

.mobile_menu__item {
  color: $black;
  font-family: 'geometria_medium', sans-serif;
  font-size: 18px;
  padding: 10px 20px 10px;
  border-bottom: 1px solid #ccc;
}

.open_submenu_lvl_1,
.open_submenu_lvl_2 {
  display: block;
  position: absolute;
  right: 20px;
  top: 15px;
  width: 12px;
  height: 12px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 5px;
    background: $pink;
  }
  &::before {
    width: 12px;
    height: 2px;
  }
  &::after {
    width: 2px;
    height: 12px;
    left: 5px;
    top: 0;
  }
  &.active {
    &::after {
      display: none;
    }
  }
}

#hide_catalog {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mobile_menu__sub_menu_item {
  .open_submenu_lvl_1,
  .open_submenu_lvl_2 {
    right: 0;
  }
}

.mobile_menu__sub_menu {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.mobile_menu__sub_category_list {
  margin-left: 15px;
  margin-top: 10px;
}

.mobile_menu__sub_menu_link {
  font-size: 16px;
}

.mobile_menu__sub_category_link {
  color: $dark-gray;
  font-size: 14px;
  padding: 5px 0;
  display: block;
}

.header__mobile_menu {
  border: 1px solid #ccc;
}

.header__mobile_phone_wrapper {
  display: none;
}

@media (max-width: 1199px) {

  .header__menu_mobile_btn {
    display: block;
  }

  .header__mobile_menu {
    display: none;
  }

  .header__menu {
    display: none;
  }

  .desktop_menu {
    display: none;
  }

  .header__top {
    &::after {display: none};
  }

  .header__gamburger_menu {
    margin-right: 0;
  }

  .header__phone_wrapper {
    display: none;
  }

  .header__logo {
    text-align: left;
    .logo__text {
      text-align: center;
    }
  }

  .header__menu_mobile_btn {
    margin-top: 0;
  }

  .header__mobile_phone_wrapper {
    display: block;
  }
}
/*header responsive ends*/
/*footer responsive*/
@media (min-width: 1024px) and (max-width: 1199px) {
  .footer__contacts  {
    .footer__menu_item {
      &.adress_no_icon {
        padding-left: 20px;
        font-size: 12px;
      }
      .footer__menu_text {
        padding-left: 20px;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 1023px) {
  .footer__contacts  {
    .footer__menu_item {
      .footer__menu_text {
        display: inline-block;
      }
    }
  }
  .footer {
    text-align: center;
  }

  .footer__logo,
  .footer__additional {
    margin-bottom: 30px;
  }
}

@media (max-width: 639px) {
  .footer__information {
    margin-bottom: 30px;
  }
}
/*footer responsive ends*/

/*homepage responsive*/
@media (max-width: 1439px) {
  .sale_block__img {
    margin: auto;
    max-width: 100%;
  }
  .sale_block__img_wrapper {
    right: 0;
  }
  .sale_products__slider,
  .bestseller_products,
  .new_products__slider,
  .manufacturers__slider,
  .related__products {
    .owl-stage-outer {
      padding: 7px 0 90px;
    }
    .owl-nav {
      .owl-next {
        right: -48px;
        position: relative;
        margin: 0;
      }
      .owl-prev {
        left: -48px;
        position: relative;
        margin: 0;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .owl-carousel {
    .owl-nav {
      .owl-prev,
      .owl-next {
        width: 26px;
        height: 26px;
        background-size: cover;
      }
      .owl-prev {
        left: 28px;
      }
      .owl-next {
        right: 28px;
      }
    }
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .owl-carousel {
    .owl-nav {
      .owl-prev {
        left: -21px;
      }
      .owl-next {
        right: -21px;
      }
    }
  }
  .manufacturers {
    .owl-carousel {
      .owl-nav {
        .owl-prev {
          left: -24px;
        }
        .owl-next {
          right: -24px;

        }
      }
    }
  }
}
@media (max-width: 1199px) {

  .sale_products {
    margin-bottom: 60px;
  }

  .sale_block {
    margin-bottom: 10px;
  }

  .product_card__add_to_cart {
    display: block;
  }

  .sale_block__left_col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .sale_block__right_col {
    padding: 0 15px;
  }
  .sale_block {
    margin-top: 25px;
  }
  .blog__article_preview {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .article_preview__inner_wrapper {
    max-width: 380px;
    margin: 0 auto 30px;
  }
  .about_shop__img_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .about_shop__text_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .owl-carousel .owl-nav .owl-next {
    margin-right: 42px;
  }
  .owl-carousel .owl-nav .owl-prev{
    margin-left: 42px;
  }
  .sale_products__slider,
  .bestseller_products,
  .new_products__slider,
  .manufacturers__slider,
  .related__products {
    .owl-stage-outer {
      padding: 7px 0 90px;
    }
    .owl-nav {
      .owl-next {
        right: -45px;
        position: relative;
        margin: 0;
      }
      .owl-prev {
        left: -45px;
        position: relative;
        margin: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .blog__article_preview {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .article_preview__inner_wrapper {
    max-width: 380px;
    margin: 0 auto 30px;
  }
}

@media (min-width: 640px) and (max-width: 959px) {
  .owl-carousel .owl-nav .owl-next {
    margin-right: 20px;
  }
  .owl-carousel .owl-nav .owl-prev{
    margin-left: 20px;
  }
  .bestseller__slider,
  .sale_products__slider,
  .new_products__slider,
  .manufacturers__row {
    max-width: 30rem;
  }
  .related,
  .product__view_sale_slider {
    max-width: 30rem;
    margin: 0 auto;
    padding: 0;
  }
  .sale_products__slider,
  .bestseller_products,
  .new_products__slider,
  .manufacturers__slider,
  .related__products {
    .owl-stage-outer {
      padding: 7px 0 90px;
    }
    .owl-nav {
      .owl-next {
        right: -50px;
        position: relative;
        margin: 0;
      }
      .owl-prev {
        left: -50px;
        position: relative;
        margin: 0;
      }
    }
  }
}

@media (max-width: 959px) {
  .section_title__underline {
    width: 100%;
  }

  .search__auto_complete {
    right: -75px;
  }

  .header__search_input {
    width: 150px;
  }
}

@media (max-width: 639px) {



  .sale_products__slider,
  .bestseller_products,
  .new_products__slider,
  .manufacturers__slider,
  .related__products {
    .owl-stage-outer {
      padding: 7px 0 90px;
    }
  }

  h3 {
    margin: 20px 0 0 0;
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
    margin: 15px 0 15px;
  }

  .about_shop {
    .about_shop__title_wrapper {
      .section_title {
        margin: 5px 0 0 0;
        text-align: left;
      }
    }
  }

  .blog {
    margin-top: 0;
    padding-bottom: 0;
  }

  .sale_block {
    margin-top: 0;
  }

  .bestseller__slider,
  .sale_products__slider,
  .new_products__slider,
  .manufacturers__row {
    max-width: 15rem;
  }

  .related,
  .product__view_sale_slider {
    max-width: 15rem;
    margin: 0 auto;
    padding: 0;
  }

  /*.main_slider {
    display: none;
  }*/

  .owl-carousel .owl-nav .owl-prev {
    margin-left: 10px;
  }

  .owl-carousel .owl-nav .owl-next {
    margin-right: 10px;
  }

  .sale_block {
    display: none;
  }

  .sale_block__left_col,
  .sale_block__right_col {
    display: none;
  }

  .header__right_group {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    > .row {
      justify-content: center;
    }
  }

  .header__mobile_phone_wrapper {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .show_catalog__wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .header__logo {
    text-align: center;
    margin-right: 8%;
  }

  /*.header__search {
    display: none;
  }*/

  .header__search {
    display: block;
    position: absolute;
    top: -45px;
    right: 10px;
    margin-right: 0 !important;
    z-index: 100;
  }

  .search__auto_complete {
    right: 0;
  }

  .header__icon:last-child {
    margin-right: 0;
  }

  .header__gamburger_menu_list {
    width: 272px;
  }

  .header__cart_menu {
    left: -198px;
    right: 0;
    margin: auto;
  }

  .header__profile_menu {
    left: -119px;
    right: 0;
    margin: 0 auto;
  }
}
/*homepage responsive ends*/

/*category responsive*/
.show_filters {
  display: none;
}

#hide_filters {
  display: none;
}

@media (min-width: 640px) and (max-width: 1199px) {
  .header {
    nav {
      padding-left: 15px!important;
      padding-right: 15px!important;
    }
  }
}

@media (max-width: 1199px) {

  .category__filter {
    padding: 30px 30px 1px;
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }

  #hide_filters {
    display: block;
  }

  .filter__list {
    max-height: 100%;
  }

  .category__filter {
    display: none;
  }

  .category__filter_column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .category__products_column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .show_filters {
    display: none;
    max-width: 220px;
    height: 45px;
    margin: 0 auto 25px;
    font-size: 18px;
    padding-top: 12px;
    cursor: pointer;
  }

  .category__view {
    .product_card__outer_wrapper {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    &.sale_cat {
      .product_card__outer_wrapper {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .show_filters {
      display: block;
    }
  }
}

@media (max-width: 799px)  {
  .category__view {
    &.sale_cat {
      .product_card__outer_wrapper {
        //flex: 0 0 100%;
        //max-width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

@media (min-width: 640px) and (max-width: 799px) {
  .category__view {
    .product_card__outer_wrapper {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .product__row {
      max-width: 30rem!important;
      margin: 0 auto 30px;
    }
  }
}

@media (max-width: 639px) {

  .header {
    nav {
      padding-left: 10px!important;
      padding-right: 10px!important;
    }
  }

  .sort_by__outer_wrapper {
    text-align: center;
    .pseudo_select__list {
      text-align: left;
    }
  }

  .category__sort_by {
    top: 0;
    margin: 0 0 30px;
    display: inline-block;
  }

  .category__title {
    margin-bottom: 15px;
    border-bottom: none;
    padding-bottom: 0;
    text-align: center;
  }

  .category__view {
    .product_card__outer_wrapper {
      //flex: 0 0 100%;
      //max-width: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      //word-break: break-all;
      word-wrap: break-word;
    }
    .product_card__inner_wrapper {
      //max-width: 210px;
      margin: 0 auto;
      float: none;
    }
    .add_to_cart__button {
      width: 100%;
    }
    .product_card__title {
      font-size: 12px;
      line-height: 15px;
    }
    .product_card__brand {
      padding: 0 10px;
    }
    .product_card__img_wrapper {
      height: auto;
    }
  }
}
/*category responsive ends*/

.related__products {
  margin-left: 0!important;
  margin-right: 0!important;
}

.related {
  padding: 0;
}

/*product card responsive*/
@media (max-width: 1199px) {
  .product__left_column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    z-index: 2;
  }

  .product__right_column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
  }
}

@media (max-width: 799px) {
  .product__left_column_inner_wrapper .badges__wrapper .badge.sale {
    right: -40px;
  }

  .reviews {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .leave__testimonial {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .product__right_column_inner_wrapper {
    height: auto;
    min-height: 0;
  }
}

@media (max-width: 639px) {
  .product__right_column {
    padding: 0 10px;
  }
  .product__left_column {
    padding: 0 10px;
  }
  .product__slide {
    height: 60px !important;
    width: 60px !important;
    padding: 5px;
  }
  .product__slider {
    width: 60px;
  }
  .product__slider {
    .slick-arrow {
      margin-left: 12px;
    }
  }
  .product__view {
    .product__img_wrapper {
      height: 290px;
    }
    .img__popup {
      .product__img {
        padding-left: 10px;
      }
    }
  }
  .product__left_column_inner_wrapper {
    height: auto;
    min-height: 401px;
    padding: 55px 10px;
    .badges__wrapper {
      .badge.sale {
        right: -19px;
      }
    }
  }
}

@media (max-width: 499px) {

  .product__add_to_cart {
    margin: 0 auto 30px;
    float: none;
    display: block;
  }

  .wish_list__btn {
    margin: 0 auto;
    float: none;
    display: block;
  }

  .product__advantages_item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
    &:nth-child(1) {
      text-align: center;
      .product__advantages_text {
        padding-left: 25px;
      }
    }
    &:nth-child(2) {
      .product__advantages_text {
        padding-left: 32px;
      }
    }
    &:nth-child(3) {
      text-align: center;
      .product__advantages_text {
        padding-left: 22px;
      }
    }
  }
  .product__top_row {
    margin-bottom: 0;
  }
  .product__right_column_inner_wrapper {
    padding: 15px;
  }
  .product__tabs_title {
    padding: 14px 7px;
    font-size: 12px;
  }
  .related {
    .product_card__outer_wrapper {
      padding: 0 15px;
    }
  }
}
/*product card responsive ends*/

/*news responsive*/
@media (max-width: 1199px) {
  .single_article__left_col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .single_article__right_col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .single_article__image_wrapper {
    height: auto;
  }

  .single_article__image {
    position: relative;
  }
}

@media (max-width: 639px) {
  .social__list {
    text-align: center;
  }
  .single_article__readmore {
    margin: 0 auto 10px;
  }
}
/*news responsive ends*/

/*single article responsive*/
@media (max-width: 1199px) {
  .latest_news__wrapper {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .single_post__wrapper {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 639px) {
  .single_post__readmore {
    &:not(.prev) {
      float: right;
      margin-right: 0;
    }
  }
}

@media (max-width: 399px) {
  .single_post__readmore {
    display: none;
  }

  .single_post__text {
    margin-bottom: 10px;
  }

  .post__bottom_row {
    margin-bottom: 10px;
  }

  .latest_news__title {
    text-align: center;
  }
}
/*single article responsive ends*/

/*cart responsive*/
@media (max-width: 1199px) {
  .cart__payment_column {
    flex: 0 0 100%;
    max-width: 100%;
    &:not(:nth-child(3)) {
      margin-bottom: 30px;
    }
  }
  .delivery_methods__wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .payment_methods__wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .unauthorized_user__tabs_outer_wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .checkout__list_outer_wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .unauthorized_user__tabs_item[data-attr="tab_heading#6"] {
    margin-bottom: 30px;
  }
  #sticky_block {
    &.sticky {
      /*right: 137px;*/
      width: 357px;
    }
  }
  .unauthorized_new_user__column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .unauthorized_user__column {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pay_with_bonuses__bonuses_total {
    text-align: center;
  }
  .pay_with_bonuses__bonuses_total_val {
    text-align: center;
  }
  .pay_with_bonuses__left_wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pay_with_bonuses__right_wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 799px) {
  label.bay_bay_theme {
    font-size: 13px;
  }
  .pay_with_bonuses__bonus_input {
    width: 90px;
  }
  .payment_methods__wrapper {
    input.bay_bay_theme,
    select.bay_bay_theme {
      max-width: 280px;
    }
  }
  .payment_column__inner {
    max-width: 280px;
    //margin: 0 auto;
  }
  .pay_with_bonuses__limit_text {
    text-align: center;
  }
  .pay_with_bonuses__title {
    text-align: center;
  }
  .unauthorized_user__tabs_outer_wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .checkout__list_outer_wrapper {
    /*flex: 0 0 100%;
    max-width: 100%;*/
    margin: 0 auto 50px;
  }
  .checkout__list_outer_wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .cart__product {
    position: relative;
  }
  .cart__product_inner_col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cart__product_column {
    display: none;
  }
  .cart__product_img_wrapper {
    margin-top: 20px;
  }
  .cart__product_title {
    margin-bottom: 25px;
  }
  .cart__product_remove {
    width: 20px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 15px;
    margin-right: 15px;
  }
  .cart__product_quantity {
    margin-bottom: 25px;
  }
  .cart__product_bonuses {
    //margin-top: 25px;
    margin-bottom: 25px;
    display: block;
  }
  .cart__product_price {
    width: 100%;
    text-align: center;
    padding-right: 0;
    margin: 0 0 30px;
  }
  .total_row__left {
    display: none;
  }
  .total_row__right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cart__product {
    margin-top: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
  .cart__outer {
    box-shadow: none;
  }
}

/*@media (max-width: 639px) {

}*/
/*cart responsive ends*/

/*mobile & responsive ends*/

.reveal-overlay {
  overflow-y: initial;
}

.reset_pass__message {
  color: red;
  margin-bottom: 15px;
}

/*underline responsive bug fix*/
.section_title__underline {
  max-width: 100%;
}

/*responsive slider arrows*/
@media (max-width: 639px) {
  .owl-carousel {
    .owl-nav {
      .owl-next {
        margin-right: 26px;
        width: 26px;
        height: 26px;
      }
      .owl-prev {
        margin-left: 26px;
        width: 26px;
        height: 26px;
      }
    }
  }
}

/*bay bay club*/
.bonus_text_large {
  font-size: 14px;
  color: $black;
  .bonus_text_pink {
    color: $pink;
    font-size: 16px;
    font-family: 'geometria_bold', sans-serif;
  }
}

.single_brand__title {
  font-size: 36px;
  font-family: 'geometria_bold', sans-serif;
  color: #222;
  text-transform: uppercase;
}

.main_slider {
  @media (min-width: 768px) {
    .mobile_slider__wrapper {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .mobile_slider__wrapper {
      display: block;
      max-height: 408px;
    }
    .main_slider__wrapper {
      display: none;
    }
    .mobile_slider__wrapper {
      position: relative;
      .owl-nav {
        bottom: 0;
      }
    }
  }
}

.ask_about_product__btn {
  text-align: center;
  width: 160px;
  height: 45px;
  font-size: 14px;
  cursor: pointer;
  display: none;
  float: left;
  background-color: transparent;
  border: 1px solid #1daa22;
  color: $black;
  font-family: 'geometria_regular', sans-serif;
  line-height: 18px;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  padding-top: 13px;
  box-sizing: border-box;
}

.add_to_cart__modal {
  &.about_product {
    .modal_btn {
      margin: 30px auto 30px;
      padding: 2px 20px 0;
      cursor: pointer;
    }
    label[for="about_product_phone"],
    label[for="about_product_email"] {
      display: block;
      color: #222;
      font-family: geometria_regular,sans-serif;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }
    #about_product_phone,
    #about_product_email {
      display: block;
      color: #222;
      line-height: 18px;
      letter-spacing: 1px;
      font-size: 13px;
      font-family: geometria_regular_italic,sans-serif;
      font-style: italic;
      border: 1px solid #e4e4e4;
      width: 100%;
      height: 36px;
      margin-bottom: 15px;
      padding-left: 15px;
      box-sizing: border-box;
    }
    .message_send {
      max-width: 200px;
      padding-top: 11px;
    }
  }
}

.single_post {
  ul {
    padding-left: 0;
    margin-left: 0;
    li:not('social_networks__item') {
      list-style: inherit;
      padding-left: 0;
      margin-left: 20px;
      -webkit-padding-start: 0;
    }
  }
  ol {
    padding-left: 0;
    margin-left: 0;
    li {
      list-style: inherit;
      padding-left: 0;
      margin-left: 20px;
    }
  }
}

/*кнопка загрузить файл*/

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile-5 + label {
  display: inline-block;
  text-align: right;
  float: right;
  > p {
    color: $pink;
    text-decoration: underline;
    margin: 0 0 10px;
    display: inline-block;
    cursor: pointer;
  }
  > span {
    display: block;
    pointer-events: none;
  }
}

.inputfile-5:focus + label,
.inputfile-5.has-focus + label,
.inputfile-5 + label:hover {
  color: darken($pink, 15%);
}



/*.inputfile-5 + label figure {
  width: 100px;
  height: 135px;
  background-color: #d3394c;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}*/

/*.inputfile-5:focus + label figure,
.inputfile-5.has-focus + label figure,
.inputfile-5 + label:hover figure {
  background-color: #722040;
}*/

/*.inputfile-5 + label figure::before,
.inputfile-5 + label figure::after {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
}*/

/*.inputfile-5 + label figure::before {
  border-top: 20px solid #dfc8ca;
  border-left: 20px solid transparent;
}*/

/*.inputfile-5 + label figure::after {
  border-bottom: 20px solid #722040;
  border-right: 20px solid transparent;
}*/

/*.inputfile-5:focus + label figure::after,
.inputfile-5.has-focus + label figure::after,
.inputfile-5 + label:hover figure::after {
  border-bottom-color: #d3394c;
}*/

/*.inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}*/

/*ie fix*/

.androidFix {
  overflow:hidden !important;
  overflow-y:hidden !important;
  overflow-x:hidden !important;
}